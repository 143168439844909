import React, { Component } from "react";

class TextArea extends Component {
  state = {};

  handleTextInput = (e) => {
    let target = { ...this.props.target };
    target[e.target.name] = e.target.value;
    let returnObject = {};
    //CHANGED HERE
    target.changed = true;
    returnObject[target.contentType] = target;

    this.props.resetState(returnObject);
  };

  render() {
    const { target, property, label } = this.props;
    return (
      <React.Fragment>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">{label}</span>
          </div>
          <textarea
            className="form-control"
            value={target[property]}
            onChange={this.handleTextInput}
            name={property}
          ></textarea>
        </div>
        {/* <label htmlFor={property}>{label}: </label>
        <input
          value={target[property]}
          onChange={this.handleTextInput}
          name={property}
        /> */}
      </React.Fragment>
    );
  }
}

export default TextArea;
