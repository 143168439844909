import React, { Component } from "react";
import DocumentLoader from "../../cms/documentLoader";
import { Container } from "react-bootstrap";
import { useParams } from 'react-router-dom';

function PaperLoader_F(props) {
  //state = {documentId:null};
  let {documentId} = useParams();

    return (
      <Container>
        
        <DocumentLoader
          //documentId={this.props.match.params.documentId}
          //documentId={props.paperId}
          documentId={documentId}
        ></DocumentLoader>
      </Container>
    );
  
}

export default PaperLoader_F;
