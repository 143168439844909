import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import UploadBox from "./subcomponents/UploadBox";
import TextInput from "./subcomponents/textInput";

class ImageBox extends Component {
  state = {
    formData: null,
    fileName: "",
  };

  _isMounted = false;

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  resetState = (state) => {
    this.props.resetState(state);
  };

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  handleGetFileData = (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    //const fileName = "testAgain.png";
    const file = files[0];
    // files.forEach((file, i) => {
    //   formData.append("File", file);
    // });

    formData.append("File", file);

    this.setState({ formData, fileName: file.name });
  };

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  handleCloseImageBox = () => {
    this.props.closeBox();
  };

  handleUploadImage = async (e) => {
    const { formData, fileName } = this.state;
    formData.append("FileName", fileName);
    formData.append("FolderId", this.props.selectedFolder.id);
    this.props.uploadImage(formData);
    //e.preventDefault();
    //let formData = this.state.formData;
    //let fileName = "testFuel.png";
    //fileName = document.getElementById("fileName").value;

    //const { data: response } = await uploadImage(formData);

    //if (response && response.success) {
    //console.log("Image uploaded!");

    //this.setState({ image: response.payload.id + ".ref" });
    //}

    //const { data: response } = await uploadImage(this.state.file);
  };

  dropImage = (droppedFiles) => {
    const files = Array.from(droppedFiles);
    const formData = new FormData();
    const file = files[0];
    formData.append("File", file);
    this.setState({ formData, fileName: file.name });
  };

  //   dropImage = (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     let droppedFiles = e.originalEvent.dataTransfer.files;
  //     const files = Array.from(droppedFiles);
  //     const formData = new FormData();
  //     const file = files[0];
  //     formData.append("File", file);
  //     this.setState({ formData, fileName: file.name });
  //   };

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  render() {
    const { formData, fileName } = this.state;
    const imageReference = this.props.imageReference;

    return (
      <React.Fragment>
        {imageReference && (
          <div className="folderBox">
            <div className="container">
              <div className="row folderBoxInternal justify-content-end">
                {/* <span className="text-center">{book && book.title}</span> */}
                {imageReference.id && (
                  <div className="col"> {imageReference.fileName}</div>
                )}

                <div className="col folderBoxAlignment">
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={this.handleCloseImageBox}
                  >
                    X
                  </Button>
                </div>
              </div>

              {imageReference.fileName && (
                <Row>
                  <img
                    style={{ width: "100%" }}
                    alt="custom description here"
                    src={
                      "http://jamesdanielsen.com/uploads/" +
                      imageReference.id +
                      ".ref"
                    }
                  ></img>
                </Row>
              )}
              {!imageReference.id && (
                <React.Fragment>
                  <Row>
                    <TextInput
                      //   className="mr-3"
                      resetState={this.resetState}
                      target={this.state}
                      property="fileName"
                      label="Filename"
                    ></TextInput>
                  </Row>
                  <Row className="justify-content-md-center">
                    {/* <div
                      onDrop={(e) => this.dropImage(e)}
                    ></div>
                    <input
                      type="file"
                      id="single"
                      onChange={this.handleGetFileData}
                    /> */}
                    <div className="align-center">
                      <UploadBox
                        handleDrop={(files) => this.dropImage(files)}
                      ></UploadBox>
                    </div>
                  </Row>
                  <Row>
                    <Button
                      size="sm"
                      variant="primary"
                      disabled={fileName === "" || formData === null}
                      onClick={this.handleUploadImage}
                    >
                      Upload
                    </Button>
                  </Row>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {/* <NewFolderModal
            show={this.state.newFolderModalShow}
            onHide={() => this.setNewFolderModalShow(false)}
            onSubmit={(name) => this.createNewFolder(name)}
          ></NewFolderModal> */}
      </React.Fragment>
    );
  }
}

//============================ UI RENDER LOGIC =============================
//==========================================================================
//==========================================================================

export default ImageBox;
