import React, { Component } from "react";
import { getDocument, getBook } from "../../services/cmsService";
import { Spinner, Container, Badge } from "react-bootstrap";
//import ParseChunk from "../cms/parseChunk";
//import { parseHeadings } from "../cms/subfunctions/ParseHeadings";
//import TableOfContents from "../cms/subcomponents/TableOfContents";
import DocumentLoader from "../cms/documentLoader";
import { useParams } from 'react-router-dom';

function ViewChapter () {
  //let state = { spinner: true, headings: [] };
  
  let {chapter} = useParams();
  // const { data: response } = getDocument(chapterId);

  // let document = response.document;
  // let content = response.content;

  // componentDidMount = async () => {
  //   const chapterId = this.props.match.params.chapter;
  //   //const bookId = this.props.bookId;
  //   //let chapters = this.props.chapters;

  //   const { data: response } = await getDocument(chapterId);

  //   let document = response.document;
  //   let content = response.content;

  //   //Parse settings variable
  //   for (var i = 0; i < content.length; i++) {
  //     content[i].settings = JSON.parse(content[i].settings);
  //     if (content[i].settings.headingLevel) {
  //       //Parse strings to ints
  //       content[i].settings.headingLevel = parseInt(
  //         content[i].settings.headingLevel
  //       );
  //     }
  //   }

  //   // if (!chapters) {
  //   //   const { data: response } = await getDocument(chapterId);
  //   //   let document = response.document;

  //   //   const { data: bookResponse } = await getBook(document.parentId);
  //   //   bookResponse.chapters.sort(function (a, b) {
  //   //     return a.sequenceNumber - b.sequenceNumber;
  //   //   });
  //   //   chapters = bookResponse.chapters;
  //   // }

  //   const { data: bookResponse } = await getBook(document.parentId);

  //   bookResponse.chapters.sort(function (a, b) {
  //     return a.sequenceNumber - b.sequenceNumber;
  //   });

  //   //Parse headings

  //   //const headings = document.toC ? parseHeadings(content) : null;

  //   this.setState({
  //     chapterId: chapterId,
  //     //chapter: document,
  //     //content: content,
  //     bookChapters: bookResponse.chapters,
  //     //bookChapters: chapters,
  //     //headings: headings,
  //     spinner: false,
  //   });

  //   window.scrollTo(0, 0);
  // };

  // handleBookCrumbClick = (id) => {
  //   let link = "/Thesis/ViewChapter/";
  //   this.props.history.push(link + id);

  //   // this.props.history.push({
  //   //   pathname: '/Thesis/ViewChapter/' + id,
  //   //   state: { chapters: this.state.chapters }
  //   // })
  // };

  // render() {
  //   const {
  //     spinner,

  //     chapterId,

  //     bookChapters,
  //   } = this.state;
    //const chapterId = this.props.match.params.chapter;

    return (
      <Container>
        <p></p>
        {/* {spinner && (
          <div className="text-center">
            <Spinner animation="border" variant="success" />
          </div>
        )}
        {bookChapters && (
          <div className="text-center">
            {bookChapters.map((chapter) => (            
              <Badge
                pill
                variant="success"
                key={"BadgeCrumb" + chapter.id}
                className={this.parseBookCrumbStatus(chapter.id)}
                onClick={() => this.handleBookCrumbClick(chapter.id)}
              >
                {chapter.title}
              </Badge>
            ))}
          </div>
        )} */}
        {chapter && (
          <DocumentLoader documentId={chapter}></DocumentLoader>
         
        )}
      </Container>
    );
  }

  // parseBookCrumbStatus = (id) => {
  //   //if (this.state.chapter.id === id) return "bookCrumbs bookCrumbsActive";
  //   if (this.state.chapterId && this.state.chapterId === id)
  //     return "bookCrumbs bookCrumbsActive";
  //   return "bookCrumbs";
  // };


export default ViewChapter;
