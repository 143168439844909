import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import ParseChunk from "../cms/parseChunk";
import { getDocument } from "../../services/cmsService";
import { parseHeadings } from "../cms/subfunctions/ParseHeadings";
import TableOfContents from "../cms/subcomponents/TableOfContents";
//import { useParams } from 'react-router-dom';

class DocumentLoader extends Component {
  state = { failed: false };

  componentDidMount = async () => {
    //const {documentId} = useParams();
    console.log("paper loader" + this.props.documentId);
    const { data: response } = await getDocument(this.props.documentId);

    if (response && response.success) {
      let elementArray = response.content;
      let document = response.document;

      for (var i in elementArray) {
        elementArray[i].settings = JSON.parse(elementArray[i].settings);
        if (elementArray[i].settings.headingLevel) {
          //Parse strings to ints
          elementArray[i].settings.headingLevel = parseInt(
            elementArray[i].settings.headingLevel
          );
        }
      }

      elementArray.sort(function (a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
      });

      let headings = document.toC ? parseHeadings(elementArray) : null;

      this.setState({ content: elementArray, document, headings });
    } else {
      this.setState({ failed: true });
    }
  };

  render() {
    const { content, failed, document, headings } = this.state;

    return (
      <React.Fragment>
        <p></p>
        {!content && !failed && (
          <div className="text-center">
            <Spinner animation="border" variant="success" />
          </div>
        )}
        {failed && <div className="text-center">Loading failed!</div>}
        {content && (
          <React.Fragment>
            {document.renderTitle && (
              <div className="bt-2 text-center">
                <div className="documentTitle mb-3">{document.title}</div>
              </div>
            )}
            {document.toC && (
              <div className="documentToC mb-3">
                <TableOfContents headings={headings} />
              </div>
            )}
            {content.map((element, index) => (
              <div key={"genericDiv" + index}>
                {ParseChunk(element, index, headings)}
              </div>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default DocumentLoader;
