export function parseHeadings(content) {

    let headings = [];
    let level1counter = 0;
    let level2counter = 0;
    let level3counter = 0;

    for (var index in content) {
        //if (content[index].type === "heading" && content[index]) console.log(content[index]);
        let heading = content[index];
        if (heading.type === "heading") {
            //console.log(heading);
            let newHeading = {
                id: heading.id,
                name: heading.content,
                //ref: React.createRef()
            };

            if (
                heading.settings.headingLevel === 1 ||
                !heading.settings.headingLevel
            ) {
                //TODO: Reset counters
                level1counter++;
                level2counter = 0;
                level3counter = 0;

                newHeading.level = 1;
                newHeading.levelText = level1counter + ".";
            } else if (heading.settings.headingLevel === 2) {
                level2counter++;
                level3counter = 0;

                newHeading.level = 2;
                newHeading.levelText = level1counter + "." + level2counter + ".";
            } else if (heading.settings.headingLevel === 3) {
                level3counter++;

                newHeading.level = 3;
                newHeading.levelText =
                    level1counter + "." + level2counter + "." + level3counter + ".";
            }

            headings[heading.id] = {
                ...newHeading
            };
        }
    }
    return headings;

}