import React, { Component } from "react";
// import { Card, Container, Row, Col } from "react-bootstrap";

class UpdaterContainer extends Component {
  state = {};

  componentDidMount = () => {
    //this.checkTokenTimer = setInterval(() => this.checkToken(), 10000);
  };

  componentWillUnmount = () => {
    //clearInterval(this.checkTokenTimer);
  };

  checkToken = () => {
    if (this.props.user) {
      var a = new Date();
      //console.log(new Date(this.props.user.refreshExpirationTime));
      var minutesRemaining = Math.floor(
        (this.props.user.refreshExpirationTime - a.getTime()) / (60 * 1000)
      );
      console.log(minutesRemaining + " minutes remaining.");
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <Card className="mt-2 mb-2"></Card> */}
        {/* <Container>
          <Row>
            <Col>1 of 2</Col>
            <Col>2 of 2</Col>
          </Row>
          <Row>
            <Col>1 of 3</Col>
            <Col>2 of 3</Col>
            <Col>3 of 3</Col>
          </Row>
        </Container> */}
      </React.Fragment>
    );
  }
}

export default UpdaterContainer;
