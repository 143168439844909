import React, { Component } from "react";
import {
  getResetPassword,
  postResetPassword
} from "../../services/userService";

class ResetPassword extends Component {
  state = { resetForm: { password: "", passwordConfirm: "" } };

  componentDidMount = async () => {
    const token = this.props.match.params.token;
    this.state.resetForm.token = token;
    //console.log("Con token: ", token);
    const { data: result } = await getResetPassword(token);
    //console.log("Confirm result: ", result);
    //console.log("Confirm result success: ", result.success);
    this.setState({ tokenConfirmationResult: result });
  };
  handleSubmitReset = async e => {
    e.preventDefault();

    const { data: result } = await postResetPassword(this.state.resetForm);

    this.setState({ result: result, success: result.success });
  };

  handleInput = e => {
    const resetForm = { ...this.state.resetForm };
    resetForm[e.target.name] = e.target.value;
    this.setState({ resetForm });

    // let email = { ...this.state.email };
    // email = e.target.value;
    // this.setState({ email });
  };

  render() {
    return (
      <div>
        <h1>Reset Password</h1>
        {this.state.tokenConfirmationResult &&
          !this.state.result &&
          this.state.tokenConfirmationResult.success && (
            <form onSubmit={this.handleSubmitReset}>
              <div className="form-group">
                <label htmlFor="password">New password: </label>
                <input
                  autoFocus
                  value={this.state.username}
                  onChange={this.handleInput}
                  name="password"
                  type="password"
                  className="form-control"
                  id="password"
                />
                <label htmlFor="passwordConfirm">Confirm new password: </label>
                <input
                  value={this.state.username}
                  onChange={this.handleInput}
                  name="passwordConfirm"
                  type="password"
                  className="form-control"
                  id="passwordConfirm"
                />
              </div>
              <button className="btn btn-primary">Reset password</button>
            </form>
          )}
        {this.state.tokenConfirmationResult &&
          !this.state.tokenConfirmationResult.success && (
            <h1>{this.state.tokenConfirmationResult.message}</h1>
          )}
        {this.state.result && !this.state.success && (
          <h1>{this.state.result.message}</h1>
        )}
        {this.state.result && this.state.success && (
          <h1>
            Your password has been successfully reset.! Please go to the login
            page.
          </h1>
        )}
      </div>
    );
  }
}

export default ResetPassword;
