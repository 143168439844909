import React, { Component } from "react";
import { Button } from "react-bootstrap";
// import NewFolderModal from "./newFolderModal";
import {
  // getChapter,
  getDocument,
  updateBook,
  updateChapters,
  createBook,
} from "../../services/cmsService";
import { toast } from "react-toastify";
import TextInput from "./subcomponents/textInput";
//import cloneDeep from "lodash/cloneDeep";

class BookBox extends Component {
  state = {
    selectedChapter: null,
    selected: null,
    newFolderModalShow: false,

    dragTarget: null,
  };

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    //const { data: response } = await getBook(this.props.bookId);
    var response = {
      success: true,
      chapters: this.props.chapters,
      book: this.props.book,
    };

    //No payload here
    if (response && response.success) {
      response.chapters.sort(function (a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
      });

      for (var index in response.chapters) {
        response.chapters[index].changed = false;
      }

      response.book.changed = false;

      this.setState({
        book: response.book,
        chapters: response.chapters,
      });
    }
  };

  loadChapter = async (chapter) => {
    let chapterId =
      chapter === null || chapter === undefined
        ? this.state.selectedChapter.id
        : chapter.id;

    // const { data: response } = await getChapter(chapterId);
    const { data: response } = await getDocument(chapterId);

    if (response && response.success) {
      // this.props.loadChapter(response.chapter, response.content);
      this.props.loadChapter(response.document, response.content);
    }
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  resetState = (state) => {
    //console.log(state);
    this.setState(state);
  };

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  handleNewChapter = () => {
    //this.props.newChapter(this.state.selected);
  };

  handleUpdateBook = async () => {
    let book = { ...this.state.book };
    const { data: response } = await updateBook(book);

    if (response && response.success) {
      book.changed = false;
      this.setState({ book });
    }
  };

  handleCreateBook = async () => {
    let book = { ...this.state.book };
    const { chapters } = this.state;
    let chapterIds = [];

    for (var i in chapters) {
      chapterIds.push(chapters[i].id);
    }

    console.log(this.props.folderId);

    const { data: response } = await createBook(
      book,
      chapterIds,
      this.props.folderId
    );

    if (response && response.success) {
      book.new = false;
      this.setState({ book });
    } else {
      console.log("Errror: ", response);
    }
  };

  updateChapters = async () => {
    //const {chapters} = this.state;
    let chapters = [].concat(this.state.chapters);
    let newChapters = [];
    for (var i in chapters) {
      if (chapters[i].changed) newChapters.push(chapters[i]);
    }

    const { data: response } = await updateChapters(
      newChapters,
      this.state.book.id
    );

    if (response && response.success) {
      toast("Chapters updated successfully!");
      //TODO: reset change
      for (i in chapters) {
        chapters.chanegd = false;
      }
      this.setState({ chapters });
    } else {
      toast.error("An error occured: " + response.message);
    }
  };

  attachDocumentToBook = (element) => {
    //const { data: response } = await attachDocument(book.id, element.id);
    let chapters = [].concat(this.state.chapters);
    let addedElement = { ...element };

    var checkStatus = chapters.find((x) => x.id === element.id);
    if (checkStatus) return null;

    addedElement.sequenceNumber = chapters.length;
    addedElement.changed = true;
    //console.log(addedElement.sequenceNumber);

    chapters.push(addedElement);
    this.setState({ chapters });
  };

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  handleChapterIconClick = (chapter) => {
    this.setState({ selectedChapter: chapter });
  };

  handleChapterIconDoubleClick = (chapter) => {
    this.setState({ selectedChapter: chapter });
    this.loadChapter(chapter);
  };

  handleCloseBookBox = () => {
    //TODO: check for changes
    // this.resetState({ book: null, chapters: null });
    //this.props.resetState({ book: null, chapters: null });
    this.props.closeBox();
  };

  handleIconDrop = (ev) => {
    ev.preventDefault();
    console.log(this.props.dragTarget);
    let target = this.props.dragTarget;
    if (target && target.contentType === "document") {
      this.attachDocumentToBook(this.props.dragTarget);
    }
  };

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  render() {
    const { chapters, selectedChapter, book } = this.state;

    //const { book } = this.props.book;

    return (
      <React.Fragment>
        {book && (
          <div className="folderBox">
            <div className="container">
              <div className="row folderBoxInternal justify-content-end">
                {/* <span className="text-center">{book && book.title}</span> */}
                <div className="col"> {book.title}</div>
                <div className="col folderBoxAlignment">
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={this.handleCloseBookBox}
                  >
                    X
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-4 folderBoxLeft folderBoxInternal">
                  <div className="row justify-content-end folderBoxDottedBottom p-2">
                    {!book.new && book.changed && (
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="mr-2"
                        onClick={this.handleUpdateBook}
                      >
                        Update book
                      </Button>
                    )}
                    {book.new && (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mr-2"
                        onClick={this.handleCreateBook}
                      >
                        Create book
                      </Button>
                    )}
                  </div>
                  <TextInput
                    className="bt-2 mr-3"
                    resetState={this.resetState}
                    target={book}
                    property="title"
                    label="Title"
                  ></TextInput>
                  <TextInput
                    className="bt-2 mr-3"
                    resetState={this.resetState}
                    target={book}
                    property="author"
                    label="Author"
                  ></TextInput>
                </div>
                <div className="col-8 folderBoxRight folderBoxInternal">
                  <div className="row justify-content-end folderBoxDottedBottom p-2">
                    {this.parseUpdateStatus() && (
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="mr-2"
                        onClick={this.updateChapters}
                      >
                        Update Chapters
                      </Button>
                    )}
                    {selectedChapter && (
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="mr-2"
                        onClick={() => this.loadChapter(null)}
                      >
                        Load Chapter
                      </Button>
                    )}
                    {/* <Button
                      variant="outline-primary"
                      size="sm"
                      className="mr-2"
                      onClick={this.handleNewChapter}
                    >
                      Add Chapter
                    </Button> */}
                    <div
                      className="chapterDropBox"
                      onDrop={(ev) => this.handleIconDrop(ev)}
                      onDragOver={this.allowDrop}
                    >
                      Drop to add
                    </div>
                  </div>
                  {chapters &&
                    chapters.map((chapter, index) => (
                      <React.Fragment key={"chapterIcon" + index}>
                        {this.buildChapterIcon(chapter)}
                      </React.Fragment>
                    ))}
                  {/* <div
                    className="dropTest"
                    onDragOver={this.allowDrop}
                    onDrop={(ev) => this.handleIconDrop(ev)}
                  ></div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <NewFolderModal
          show={this.state.newFolderModalShow}
          onHide={() => this.setNewFolderModalShow(false)}
          onSubmit={(name) => this.createNewFolder(name)}
        ></NewFolderModal> */}
      </React.Fragment>
    );
  }

  //============================ UI RENDER LOGIC =============================
  //==========================================================================
  //==========================================================================

  allowDrop = (ev) => {
    ev.preventDefault();
  };

  drag = (ev, element) => {
    if (!this.state.dragTarget) {
      //console.log("droptest!");
      this.setState({ dragTarget: element });
    }
  };

  drop = (ev, target) => {
    ev.preventDefault();
    var data = this.state.dragTarget;

    console.log("droptest!");

    var chapters = [].concat(this.state.chapters);
    var element1 = chapters.find((x) => x.id === data.id);
    var element2 = chapters.find((x) => x.id === target.id);
    let buf1 = element1.sequenceNumber;
    element1.sequenceNumber = element2.sequenceNumber;
    element2.sequenceNumber = buf1;
    element1.changed = true;
    element2.changed = true;
    console.log(buf1, element1.sequenceNumber);
    chapters.sort(function (a, b) {
      return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
    });

    this.setState({ dragTarget: null, chapters });
  };

  parseUpdateStatus = () => {
    const { chapters } = this.state;

    if (this.state.book.new) return false;

    for (var index in chapters) {
      if (chapters[index].changed) return true;
    }
    return false;
  };

  buildChapterIcon = (element) => {
    let icon = " fa fa-file ";
    let elementRef = element;

    return (
      <span
        className={"documentIconHolder " + this.parseselectedChapter(element)}
        draggable
        onDrop={(event) => this.drop(event, elementRef)}
        onDragStart={(event) => this.drag(event, elementRef)}
        onDragOver={this.allowDrop}
        onClick={() => this.handleChapterIconClick(element)}
        onDoubleClick={() => this.handleChapterIconDoubleClick(element)}
      >
        <i className={"documentIcon" + icon}></i>

        <p className="documentIconText">Chapter: {element.title}</p>
      </span>
    );
  };

  setNewFolderModalShow = (show) => {
    this.setState({ newFolderModalShow: show });
  };

  parseselectedChapter = (document) => {
    if (
      this.state.selectedChapter &&
      this.state.selectedChapter.id === document.id
    ) {
      return "documentIconSelected";
    }
    return "";
  };

  //TODO: REMOVE??
  parseSelected = (folder) => {
    if (folder && this.state.selected && folder.id === this.state.selected.id) {
      return "activeFolder";
    }
    return "inactiveFolder";
  };
}

export default BookBox;
