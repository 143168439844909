import React, { Component } from "react";
import "../../../css/papers.css";
import { Navigate } from "react-router-dom";
import PaperLoader_F from "./paperLoader_F";
import { Card, Alert, Container, Button } from "react-bootstrap";

class Papers extends Component {
  state = {
    papers: [
      {
        id: "5e944d3e79cddf0a60f2b041",
        title: "The Storyteller",
        description: "",
        status: "Published",
        readtime: "8 minute read",
      },
      {
        id: "5e9f0ab20142a41face4dcc6",
        title:
          "Pyramidal deliberative networks as a solution to the problem of scale in deliberative theory",
        description: "",
        status: "Submitted, Peer-reviewed and Rejected / Under redraft",
        readtime: "25 minute read",
      },
      {
        id: "5eab2fcecbcb1e5930ef64f0",
        title:
          "Maximising our Capacity Factor for Sustainability: Pyramidal deliberative networks, systems-thinking, and the realisation of citizen self-organisation at any scale	",
        description: "",
        status: "Under peer review",
        readtime: "40 minute read",
      },
    ],
    paperId: null
  };

  
  _isMounted = false;

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      //this.navigate = useNavigate();
    }

  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  clickViewPaper = (id) => {
    //console.log(this.navigate);
    //const navigate = useNavigate();
    //navigate("/papers/" + id, { state: { id: id, color: 'green' } });
    this.setState({paperId: id})
    //this.navigateId = "/papers/" + id;
    //console.log(this.navigateId);
    //this.navigate("/papers/" + id);
    //this.props.history.push("/papers/" + id);
  };

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  render() {
    const { papers, paperId } = this.state;
    return (
      
      <Container>
         
         {paperId && (
          
          //<PaperLoader_F paperId={paperId}></PaperLoader_F>
          <Navigate to={"/papers/" + paperId}  />
          
        )}
        {!paperId && (
          <React.Fragment>
        <Alert variant="success" className="mt-3">
          <Alert.Heading className="text-center">
            The Good, the Bad, and the wholly Unpublishable
          </Alert.Heading>
          <p className="text-center">
            You've reached the repository for my academic papers. Exciting
            stuff! Feel free to peruse.{" "}
            {/* {
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://hdl.handle.net/10962/74502"
              >
                this link.
              </a>
            } */}
          </p>
          <hr />
          <blockquote className="blockquote mb-0 text-center">
            <p>
              {" "}
              The relationship of an agent to a publisher is that of a knife to
              a throat.{" "}
            </p>
            <footer className="blockquote-footer">
              An American agent's view,{" "}
              <cite title="Source Title">Marvin Josephson</cite>
            </footer>
          </blockquote>
        </Alert>
        <p>{<i className="fa fa-smile"></i>}</p>
        {/* {this.state.papers.map((paper) => ( */}
        <React.Fragment>
          <Card className="paperCard" border="success">
            <Card.Header>
              Status:{" "}
              {<span style={{ fontWeight: "bold" }}>{papers[0].status}</span>}
            </Card.Header>
            <Card.Body>
              <Card.Title>{papers[0].title}</Card.Title>
              <Card.Text className="paperCardBody">
                Teaser: Once upon a time, a machine began telling us the stories
                of our lives…and humanity lived happily ever after.
              </Card.Text>
              <Card.Text className="paperCardBody">
                Abstract: This story is inspired by the growing feasibility of
                Decentralised Autonomous Corporations (DACs), operating by means
                of distributed blockchain technologies. The story is set in the
                near future where a decentralised corporation – powered by
                machine-learning algorithms and fed on an unending stream of
                data – has entirely penetrated the fabric of society.
                Ultimately, however, it is the narcissism of humans – their
                insatiable need to be recognised in some way – that drives the
                exponential power of the corporation. Some of the themes that
                emerge from the story are: 1) the production of social media
                content, 2) the dangers of corporate personhood, 3) the nature
                of human and non-human agency, 4) the nature of truth in the
                context of the information age, and 5) the potential impact of
                fully autonomous technologies.
              </Card.Text>
              <Card.Text className="paperCardBody">
                Details: This story was selected as one of the winners of the <strong>
                Law in the Worlds of Tomorrow Competition </strong>, hosted by the
                Institute for Information Law at the University of Amsterdam.
                You can find more information about the competition and the
                symposium{" "}
                {
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ivir.nl/science-fiction-information-law-winners-announced/"
                  >
                    here
                  </a>
                }
                . The story was published in the journal{" "}
                {
                  <span style={{ fontStyle: "italic" }}>
                    Internet Policy Review
                  </span>
                }
                . You can find the special issue editorial{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policyreview.info/scifi-infolaw"
                >
                  here
                </a>
                .
              </Card.Text>

              <Button
                variant="outline-success"
                className="mr-3"
                onClick={() =>
                  window.open(
                    "https://policyreview.info/essays/scifi/storyteller"
                  )
                }
              >
                Read it (external)
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => this.clickViewPaper(papers[0].id)}
              >
                Read it (internal)
              </Button>
            </Card.Body>
            <Card.Footer className="text-muted">
              {papers[0].readtime}
            </Card.Footer>
          </Card>
          <p></p>
          <Card className="paperCard" border="danger">
            <Card.Header>
              Status:{" "}
              {<span style={{ fontWeight: "bold" }}>{papers[1].status}</span>}
            </Card.Header>
            <Card.Body>
              <Card.Title>{papers[1].title}</Card.Title>
              <Card.Text>Description coming soon!</Card.Text>
              <Button
                variant="outline-primary"
                onClick={() => this.clickViewPaper(papers[1].id)}
              >
                Check it out
              </Button>
            </Card.Body>
            <Card.Footer className="text-muted">
              {papers[1].readtime}
            </Card.Footer>
          </Card>
          <p></p>
          <p></p>
          <Card className="paperCard" border="warning">
            <Card.Header>
              Status:{" "}
              {<span style={{ fontWeight: "bold" }}>{papers[2].status}</span>}
            </Card.Header>
            <Card.Body>
              <Card.Title>{papers[2].title}</Card.Title>
              <Card.Text>Description coming soon!</Card.Text>
              <Button
                variant="outline-primary"
                onClick={() => this.clickViewPaper(papers[2].id)}
              >
                Check it out
              </Button>
            </Card.Body>
            <Card.Footer className="text-muted">
              {papers[2].readtime}
            </Card.Footer>
          </Card>
        </React.Fragment>
        </React.Fragment>
        )}
        {/* ))} */}
      </Container>
    );
  }
}

//============================ UI RENDER LOGIC =============================
//==========================================================================
//==========================================================================

export default Papers;
