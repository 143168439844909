import React, { Component } from "react";
import { getBook } from "../../services/cmsService";
import {
  Card,
  Spinner,
  Container,
  Alert,
  Accordion,
  Button,
} from "react-bootstrap";
import PDLogo from "../../img/PDLogo.jpg";
import DocumentLoader from "../cms/documentLoader";
import { Navigate, useNavigate } from "react-router-dom";

class Thesis extends Component {
  state = { spinner: true, chapterId: null };
  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    const { data: response } = await getBook("5e832a4619f1382ecc0b6536");

    //possibly use try catch to avoid this error
    if (this._isMounted && response) {
      this.setState({
        book: response.book,
        chapters: response.chapters,
        spinner: false,
      });
    }
  };

  // testClick = () => {
  //   console.log("also a click");
  // };

  handleChapterClick = (id) => {
    
    this.setState({chapterId:id})
    // this.props.history.push({
    //   pathname: "/Thesis/ViewChapter/" + id,
    //   state: { bookId: this.state.book.id, chapters: this.state.chapters },
    // });
  };

  render() {
    const { spinner, book, chapters, chapterId } = this.state;

    return (
      <Container>
        {chapterId && (
          
          //<PaperLoader_F paperId={paperId}></PaperLoader_F>
          <Navigate to={"/Thesis/ViewChapter/" + chapterId}  />
          
        )}
        <Alert variant="success" className="mt-3">
          <Alert.Heading className="text-center">
            Thanks for visiting!
          </Alert.Heading>
          <p>
            You've reached the digital version of my PhD thesis. This version is
            also part of a content management system that I'm building. All the
            text is stored in a database and can be managed and updated from
            this website. All the content of my original thesis is here, except
            for the footnotes. If you are interested you can find the official
            pdf version of this document, footnotes included, by following{" "}
            {
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://hdl.handle.net/10962/74502"
              >
                this link.
              </a>
            }
          </p>
          <hr />
          <p className="mb-0">
            P.S. I'm planning to work on a more interesting and readable version
            of this book. Check back soon.
          </p>
        </Alert>
        <div className="text-center mt-3 mb-3 bookTitle">
          Pyramidal Deliberative Democracy
        </div>
        <div className="text-center mb-4">
          <img src={PDLogo} width="20%" alt="PDLogo"></img>
        </div>
        <div className="text-center mb-1 stext">
          A thesis submitted in fulfilment of the requirements for the degree of
        </div>
        <div className="text-center mb-3 stext">DOCTOR OF PHILOSOPHY</div>

        <div className="text-center mb-3 stext">
          Rhodes University, South Africa
        </div>
        <div className="text-center mb-3 stext">James Danielsen, 2019</div>
        <div className="text-center mb-3 stext">ABSTRACT:</div>
        <p className="quoteblock mb-3">
          This dissertation has two main objectives. First, to outline an
          ICT-facilitated model of democracy called ‘pyramidal democracy’ that
          reconciles deliberative democracy with mass engagement. Second, to
          suggest how this model of democracy might engender the democratisation
          of the global economy and thus the provision of a basic level of
          economic security for all global citizens. At the core of the model is
          the pyramidal deliberative network, a means of organising citizens
          into small online deliberative groups and linking these groups
          together by means of an iterative process of delegate-selection and
          group-formation. The pyramidal network enables citizens to aggregate
          their preferences in a deliberative manner, and then project social
          power by authorizing the delegates at the top-tier of the pyramidal
          network to communicate their social demands to elected officials or to
          other points of authority. The envisioned outcome is the
          democratisation of the public sphere by means of the proliferation of
          deliberative networks in the government, market, and civil society
          spheres. Transnational pyramidal networks may make it feasible to
          instantiate a new citizen-based schema of global governance and,
          thereby, facilitate the reform of the United Nations and enable a
          transition towards global peace, sustainability, and distributive
          justice. Distributive justice might be achieved by means of
          implementing the six components of a democratised economy:
          participatory budgeting, fee-and-dividend taxes, a basic income,
          monetary reform, workplace democracy, and the sharing economy. Taken
          together, these components might enable the universal provision of a
          social minimum – a universal basic income sufficient for basic
          security and real freedom. Taken to its logical conclusion, a
          democratised economy may also enable a transition towards a
          post-scarcity economic order characterised by a maximal stock of
          humanmade and natural capital that would not exceed the sustainable
          carrying capacity of the earth.
        </p>
        <h2 className="text-center mb-3">Table of Contents</h2>
        {spinner && (
          <div className="text-center">
            <Spinner animation="border" variant="success" />
          </div>
        )}
        {book && (
          <React.Fragment>
            <div className="documentToC">
              {chapters &&
                chapters.map((chapter) => (
                  <div key={"bookRow" + chapter.id} className="TOCHolder">
                    <div
                      //className="TOC"
                      className="TOCInternal TOCHover"
                      onClick={() => this.handleChapterClick(chapter.id)}
                      //onClick={() => <Navigate to={"/Thesis/ViewChapter/" + chapter.id}  />}
                      //onClick={() => useNavigate("/Thesis/ViewChapter/" + chapter.id)}
                      //<Navigate to={"/papers/" + paperId}  />
                    >
                      {chapter.title}
                    </div>
                  </div>
                ))}
            </div>
            {/* <Container>
              <Row>
                <Col className="col-2"></Col>
                <Col>
                  {chapters &&
                    chapters.map((chapter) => (
                      <div
                        key={"bookRow" + chapter.id}
                        //className="TOC"
                        className="TOCInternal TOCHover"
                        onClick={() => this.handleChapterClick(chapter.id)}
                      >
                        {chapter.title}
                      </div>
                    ))}
                </Col>
                <Col className="col-2"></Col>
              </Row>
            </Container> */}
          </React.Fragment>
        )}
        <p></p>
        <p></p>
        <p></p>
        <h2 className="text-center mb-3 mt-6">Criticism and Commentary</h2>
        <div className="mb-3 stext">
          Here are the notes from the reviewers who examined my thesis. Some
          comments may refer to changes that have already been made during the
          revision process. However, many of the major criticisms still stand.
            </div>

            
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Examiner 1: Professor Kumar Chandra(University of York, Canada)</Accordion.Header>
                    <Accordion.Body>
                        <DocumentLoader documentId="5e9119af5e73f21eb4b8f125"></DocumentLoader>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> 
            <Accordion>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Reviewer 2: Professor Daniel De Kadt (University of California
                        Merced, United States)</Accordion.Header>
                    <Accordion.Body>
                        <DocumentLoader documentId="5e91160e5e73f21eb4b8f10d"></DocumentLoader>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Reviewer 3: Dr Deepak Mistrey (University of KwaZulu-Natal,
                South Africa)</Accordion.Header>
                    <Accordion.Body>
                        <DocumentLoader documentId="5e911bef5e73f21eb4b8f137"></DocumentLoader>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        
        {/* <div style={{ height: "500px" }}></div> */}
      </Container>
    );
  }
}

export default Thesis;

/* <Card key={book.id}>
<Card.Header as="h6">Thesis</Card.Header>
<Card.Body>
  <Card.Title>{book.title}</Card.Title>
  <Card.Text>A book by {book.author}</Card.Text>
  <Container>
    {chapters &&
      chapters.map(chapter => (
        <Row key={"bookRow" + chapter.id}>
          <div
            // className="btn btn-primary"
            className="TOC"
            onClick={() => this.handleChapterClick(chapter.id)}
          >
            {chapter.title}
          </div>
        </Row>
      ))}
  </Container>

</Card.Body>
</Card> */

/*
 * <Accordion>
          <Card>
            <Card.Header>
                      
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Reviewer 1: Professor Daniel De Kadt (University of California
                Merced, United States)
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <DocumentLoader documentId="5e91160e5e73f21eb4b8f10d"></DocumentLoader>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                Examiner 2: Professor Kumar Chandra(University of York, Canada)
              </Accordion.Toggle >
            </Card.Header >
    <Accordion.Collapse eventKey="1">
        <Card.Body>
            {" "}
            <DocumentLoader documentId="5e9119af5e73f21eb4b8f125"></DocumentLoader>
        </Card.Body>
    </Accordion.Collapse>
          </Card >
    <Card>
        <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                Examiner 3: Dr Deepak Mistrey (University of KwaZulu-Natal,
                South Africa)
            </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
            <Card.Body>
                {" "}
                <DocumentLoader documentId="5e911bef5e73f21eb4b8f137"></DocumentLoader>
            </Card.Body>
        </Accordion.Collapse>
    </Card>
        </Accordion >
 * 
 * 
 * 
 * 
 */
