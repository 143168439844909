import http from "./httpService";

import config from "../config.json";

const apiEndpoint = config.apiUrl + "admin/";

export function getUsers() {
    return http.get(apiEndpoint + "getusers");
}

export function adminHandler(target, command, payload) {
    const data = {
        Target: target,
        Command: command,
        Payload: payload
    };
    return http.post(apiEndpoint + "adminhandler", data);
}