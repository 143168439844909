import React, { Component } from "react";
import { toast } from "react-toastify";
import { login } from "../../services/userService";
import { Link } from "react-router-dom";
import {Container} from "react-bootstrap";

class Login extends Component {
  state = {
    account: { username: "", password: "" }
  };

  handleSubmitLogin = async e => {
    e.preventDefault();

    const { username, password } = this.state.account;

    try {
      const { data: response } = await login(username, password);
      console.log(response);
      if (response.success) {
        toast.info("Login Successful");

        //Persists JWT token
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("expirationTime", response.expirationTime);

        //this.props.history.push("/");
        window.location = "/";
      } else {
        toast.error(response.message);
        //alert("Login failed");
      }
    } catch (error) {
      toast.error("Some crazy login error!: ", error);
    }
  };

  handleInput = e => {
    const account = { ...this.state.account };
    account[e.target.name] = e.target.value;
    this.setState({ account });
  };

  render() {
    return (
      <Container>
      <div>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmitLogin}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              autoFocus
              value={this.state.username}
              onChange={this.handleInput}
              name="username"
              type="text"
              className="form-control"
              id="username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              value={this.state.password}
              onChange={this.handleInput}
              name="password"
              type="password"
              className="form-control"
              id="password"
            />
          </div>
          <button className="btn btn-primary">Login</button>
        </form>
        <p></p>
        <Link to="/Accounts/ForgotPassword">I forgot my password!</Link>
      </div>
      </Container>
    );
  }
}

export default Login;
