import React, { Component } from "react";
import { Button, Dropdown, ButtonGroup, SplitButton } from "react-bootstrap";
import NewFolderModal from "./newFolderModal";
import "../../css/folderBox.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBook, faFileImage } from "@fortawesome/free-solid-svg-icons";
// import { fa } from "@fortawesome/fontawesome-svg-core";

import {
  getFolderContents,
  deleteFolder,
  getFolders,
  getBook,
  createFolder,
  getDocument,
} from "../../services/cmsService";

class FolderBox extends Component {
  state = {
    selectedDocument: null,
    selectedFolder: null,
    newFolderModalShow: false,
    dropDownSelection: "2",
  };

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    const { data: response } = await getFolders();
    const folders = response.folders;

    //Find rootFolder
    const rootFolder = folders.find((x) => x.isRoot === true);

    let folderStructure = this.createFolderStructure(folders, rootFolder);
    this.setState({
      folders: folders,
      folderStructure: folderStructure,
      folderContents: null,
      rootFolder: rootFolder,
      selectedFolder: this.props.selectedFolder,
    });
  };

  // componentWillReceiveProps = () => {
  //   console.log("FCUPDATES: ", this.props.folderContentUpdate);
  // };

  componentDidUpdate = () => {
    const folderContentUpdate = this.props.folderContentUpdate;
    //const { folderContents } = this.folderContents;
    if (folderContentUpdate) {
      let folderContents =
        this.state.folderContents != null
          ? [].concat(this.state.folderContents)
          : [];
      folderContents.push(folderContentUpdate);
      this.setState({ folderContents });
      this.props.resetState({ folderContentUpdate: null });
    }
    //console.log("FCUPDATES: ", this.props.folderContentUpdate);
  };

  loadDocument = async (document) => {
    let docId =
      document === null || document === undefined
        ? this.state.selectedDocument.id
        : document.id;

    const { data: response } = await getDocument(docId);

    if (response && response.success) {
      this.props.loadDocument(response.document, response.content);
    }
  };

  loadBook = async (book) => {
    let bookId =
      book === null || book === undefined
        ? this.state.selectedDocument.id
        : book.id;

    const { data: response } = await getBook(bookId);

    if (response && response.success) {
      this.props.loadBook(response.book, response.chapters);
    }
  };

  loadImage = (imageReference) => {
    console.log("load image");
    this.props.loadImage(imageReference);
  };

  getFolderContents = async (folderId) => {
    const { data: response } = await getFolderContents(folderId);
    if (response && response.success) {
      return response.payload;
    }
    return null;
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  createFolderStructure = (folders, rootFolder) => {
    let folderStructure = [];
    //Find Level 1
    for (var index in folders) {
      if (!folders[index].open) folders[index].open = false;
      if (folders[index].parentId === rootFolder.id) {
        folderStructure.push(folders[index]);
      }
    }
    return folderStructure;
  };

  handleFolderSelect = async (folder) => {
    let folders = [].concat(this.state.folders);
    let folderContents = null;
    let selectedFolder = folders.find((x) => x.id === folder.id);
    selectedFolder.open = !selectedFolder.open;

    if (selectedFolder.open) {
      //Only documents? !!!
      //const { data: response } = await getFolderContents(selectedFolder.id);
      //folderContents = response.documents;

      folderContents = await this.getFolderContents(selectedFolder.id);
    }

    this.props.resetState({ selectedFolder: folder });

    this.setState({
      selectedFolder: selectedFolder,
      folders: folders,
      folderContents: folderContents,
      selectedDocument: null,
    });
  };

  handleNewFolder = () => {
    this.setState({ newFolderModalShow: true });
  };

  // handleNewDocument = () => {
  //   //this.setState({ newFolderModalShow: true });
  //   this.props.newDocument(this.state.selected);
  // };

  // parseLoadedDocument = (document, content) => {
  //   console.log(document, content);
  // };

  handleDeleteFolder = async () => {
    //TODO: check folder can be deleted
    //const selectedFolder = this.props.selectedFolder;
    const selectedFolder = this.state.selectedFolder;
    //const { data: response } = await deleteFolder(this.state.selected.id);
    const { data: response } = await deleteFolder(selectedFolder.id);

    if (response.success) {
      let folders = [].concat(this.state.folders);
      const deleteIndex = folders.findIndex((x) => x.id === selectedFolder.id);
      folders.splice(deleteIndex, 1);

      //Recreate folder structure
      let folderStructure = this.createFolderStructure(
        folders,
        this.state.rootFolder
      );
      // this.setState({
      //   folders: folders,
      //   folderStructure: folderStructure,
      //   selected: null,
      // });

      this.setState({
        selectedFolder: null,
        folders: folders,
        folderStructure: folderStructure,
      });
      //Propagate??
      this.props.resetState({ selectedFolder: null });
    }
  };

  createNewFolder = async (name) => {
    this.setNewFolderModalShow(false);
    const { selectedFolder, rootFolder, folders } = this.state;
    // let newFolder = {
    //   ParentId: this.state.selected
    //     ? this.state.selected.id
    //     : this.state.rootFolder.id,
    //   Name: name,
    // };
    let newFolder = {
      ParentId: selectedFolder ? selectedFolder.id : rootFolder.id,
      Name: name,
    };
    const { data: response } = await createFolder(newFolder);
    if (response.success) {
      let newFolders = [].concat(folders);
      //console.log(this.state.folders);
      newFolders.push(response.folder);
      let folderStructure = this.createFolderStructure(
        newFolders,
        this.state.rootFolder
      );
      this.setState({ folders: newFolders, folderStructure: folderStructure });
    }
  };

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  handleAddNewSelect = (event) => {
    this.setState({ dropDownSelection: event });
  };

  handleAddNewClick = (event) => {
    this.props.addNew(this.state.dropDownSelection, this.state.selectedFolder);
    // this.props.addNew(this.state.dropDownSelection, this.state.selected);
    // const { dropDownSelection } = this.state;
    // if (dropDownSelection === "1") {
    //   this.props.newDocument(this.state.selected);
    // }
    // if (dropDownSelection === "2") {
    //   this.props.newDocument(this.state.selected);
    // }
  };

  handleDocumentIconClick = (document) => {
    this.setState({ selectedDocument: document });
  };

  handleDocumentIconDoubleClick = (document) => {
    this.setState({ selectedDocument: document });
    if (document.contentType === "document") this.loadDocument(document);
    if (document.contentType === "book") this.loadBook(document);
    if (document.contentType === "imageReference") this.loadImage(document);
  };

  drag = (ev, element) => {
    //ev.dataTransfer.setData("dragTarget", element);
    this.props.resetState({ dragTarget: element });
    console.log(element.id);
    //console.log("Event:", ev);
    // if (!this.state.dragTarget) {
    //   this.setState({ dragTarget: element });
    // }
  };

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  render() {
    const {
      folderStructure,
      rootFolder,
      folderContents,
      selectedFolder,
      selectedDocument,
    } = this.state;

    //const selected = this.props.selectedFolder;

    return (
      <React.Fragment>
        <p>
          {this.props.folderContentUpdate &&
            this.props.folderContentUpdate.contentType}
        </p>
        <div className={"folderBox " + this.parseDisabledStatus()}>
          <div className="container">
            <div className="row folderBoxInternal text-center">
              <span className="text-center">
                {rootFolder && rootFolder.name}
              </span>
            </div>
            <div className="row">
              <div className="col-4 folderBoxLeft folderBoxInternal">
                <div className="row justify-content-end folderBoxDottedBottom p-2">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={this.handleNewFolder}
                  >
                    Add Folder
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="ml-1"
                    onClick={this.handleDeleteFolder}
                    disabled={selectedFolder === null ? true : false}
                  >
                    Delete Folder
                  </Button>
                </div>
                {rootFolder && (
                  <div
                    onClick={() => this.handleFolderSelect(rootFolder)}
                    className={this.parseSelected(rootFolder) + " rootFolder"}
                  >
                    <i className={"fa fa-folder mr-2"}></i>
                    {rootFolder.name}
                  </div>
                )}

                {folderStructure &&
                  folderStructure.map((folder, index) => (
                    <React.Fragment key={"folderFrament" + index}>
                      {this.buildFolder(folder, 0)}
                      {folder.open && this.parseFolderlevel(folder, 0)}
                    </React.Fragment>
                  ))}
              </div>
              <div className="col-8 folderBoxRight folderBoxInternal">
                <div className="row justify-content-end folderBoxDottedBottom p-2">
                  {selectedDocument &&
                    selectedDocument.contentType === "document" && (
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="mr-2"
                        onClick={() => this.loadDocument(null)}
                      >
                        Load Document
                      </Button>
                    )}
                  {selectedDocument && selectedDocument.contentType === "book" && (
                    <Button
                      variant="outline-success"
                      size="sm"
                      className="mr-2"
                      onClick={() => this.loadBook(null)}
                    >
                      Load Book
                    </Button>
                  )}
                  {selectedDocument &&
                    selectedDocument.contentType === "imageReference" && (
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="mr-2"
                        onClick={() => this.loadImage(null)}
                      >
                        Load Image
                      </Button>
                    )}
                  <SplitButton
                    as={ButtonGroup}
                    // size="lg"
                    disabled={
                      !(selectedFolder && selectedFolder.id !== rootFolder.id)
                    }
                    title={this.parseDropDownTitle()}
                    onClick={(eventKey) => this.handleAddNewClick(eventKey)}
                    onSelect={(eventKey) => this.handleAddNewSelect(eventKey)}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      // onChange={this.handleDropDownChange("1")}
                    >
                      Add Document
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2">Add Image</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="7">Add Book</Dropdown.Item>
                  </SplitButton>
                  {/* <Button
                    variant="outline-primary"
                    size="sm"
                    disabled={!(selected && selected.id !== rootFolder.id)}
                    onClick={this.handleNewDocument}
                  >
                    Add Document
                  </Button> */}
                </div>
                {folderContents && this.buildFolderContents()}
              </div>
            </div>
          </div>
        </div>
        <NewFolderModal
          show={this.state.newFolderModalShow}
          onHide={() => this.setNewFolderModalShow(false)}
          onSubmit={(name) => this.createNewFolder(name)}
        ></NewFolderModal>
      </React.Fragment>
    );
  }

  //============================ UI RENDER LOGIC =============================
  //==========================================================================
  //==========================================================================

  buildFolderContents = () => {
    const { folderContents } = this.state;
    return (
      <React.Fragment>
        {folderContents.map((document, index) => (
          <React.Fragment key={"folderElement" + index}>
            {this.buildFolderIcon(document)}
          </React.Fragment>
          // <span
          //   key={"documentIconHolder" + document.id}
          //   className={
          //     "documentIconHolder " + this.parseSelectedDocument(document)
          //   }
          //   onClick={() => this.handleDocumentIconClick(document)}
          //   onDoubleClick={() => this.handleDocumentIconDoubleClick(document)}
          // >
          //   <i
          //     key={"documentIcon" + document.id}
          //     className={"documentIcon fa fa-file"}
          //   ></i>

          //   <span key={"diconText" + document.id} className="documentIconText">
          //     {document.title}
          //   </span>
          // </span>
        ))}
      </React.Fragment>
    );
  };

  parseDisabledStatus = () => {
    if (this.props.disabled) return "disabledFolderBox";
    return "";
  };

  parseDropDownTitle = () => {
    const { dropDownSelection } = this.state;
    if (dropDownSelection === "1") return "Add Document";
    if (dropDownSelection === "2") return "Add Image";
    if (dropDownSelection === "7") return "Add Book";
  };

  buildFolderIcon = (element) => {
    var elementRef = element;
    let icon = "";
    let text = "";

    switch (element.contentType) {
      case "document":
        icon = " fa fa-file ";
        text = element.title;
        break;
      case "book":
        icon = " fa fa-book ";
        text = element.title;
        break;
      case "imageReference":
        icon = " fa fa-image ";
        text = element.fileName;
        break;
      default:
        icon = "";
        text = "Icon not found!";
        break;
    }

    // switch (element.contentType) {
    //   case "document":
    //     icon = faWord;
    //     break;
    //   case "book":
    //     icon = faBook;
    //     break;
    //   case "imageReference":
    //     icon = faFileImage;
    //     break;
    // }

    return (
      <span
        draggable
        onDragStart={(event) => this.drag(event, elementRef)}
        className={"documentIconHolder " + this.parseSelectedDocument(element)}
        onClick={() => this.handleDocumentIconClick(element)}
        onDoubleClick={() => this.handleDocumentIconDoubleClick(element)}
      >
        <i className={"documentIcon " + icon}></i>
        {/* <FontAwesomeIcon className={"documentIcon"} icon={icon} /> */}

        <p className="documentIconText">{text}</p>
      </span>
    );
  };

  buildFolder = (folder, level) => {
    return (
      <div
        key={"folder" + folder.id}
        onClick={() => this.handleFolderSelect(folder)}
        className={this.parseSelected(folder) + " folder ml-1 fp-" + level}
      >
        <i
          key={"folderIcon" + folder.id}
          className={"fa fa-folder" + (folder.open ? "-open" : "") + " mr-2"}
        ></i>

        {folder.name}
      </div>
    );
  };

  parseFolderlevel = (folder, level) => {
    let folderLevel = [];
    level++;
    const folders = this.state.folders;

    for (var index in folders) {
      if (folders[index].parentId === folder.id) {
        folderLevel.push(folders[index]);
      }
    }
    return folderLevel.map(
      (folder) => (
        <div key={"folderDiv" + folder.id}>
          {this.buildFolder(folder, level)}
          {folder.open && this.parseFolderlevel(folder, level)}
        </div>
      )
      // <div className="pl-1">
      //   <i className="fa fa-folder mr-2"></i>
      //   {folder.name}
      // </div>
    );
  };

  setNewFolderModalShow = (show) => {
    this.setState({ newFolderModalShow: show });
  };

  parseSelectedDocument = (document) => {
    if (
      this.state.selectedDocument &&
      this.state.selectedDocument.id === document.id
    ) {
      return "documentIconSelected";
    }
    return "";
  };

  parseSelected = (folder) => {
    const selectedFolder = this.state.selectedFolder;
    //console.log(folder, selectedFolder);
    // if (folder && this.state.selected && folder.id === this.state.selected.id) {
    if (folder && selectedFolder && folder.id === selectedFolder.id) {
      return "activeFolder";
    }
    return "inactiveFolder";
  };
}

export default FolderBox;
