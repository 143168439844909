import React from "react";
import { Modal, Button } from "react-bootstrap";

function NewFolderModal(props) {
  // class NewFolderModal extends Component {
  //   checkForSubmit = (e) => {
  //     if (e.keyCode === 13) {
  //       props.onSubmit(document.getElementById("newFolderInput").value);
  //     }
  //   };
  //   render() {
  return (
    <Modal {...props} size="lg" aria-labelledby="newFolderModal" centered>
      <Modal.Header>
        <Modal.Title id="newFolderModal">Create New Folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p>
          <label htmlFor="newFolderInput">Folder name: </label>
          <input
            autoFocus
            // value={chapter.parentId}
            // className="mr-3"
            // onChange={this.handleTextBarInput}
            id="newFolderInput"
            name="newFolderInput"
            // onKeyDown={(e) => checkForSubmit(e)}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() =>
            props.onSubmit(document.getElementById("newFolderInput").value)
          }
        >
          Submit
        </Button>
        <Button onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
  //   }
}

export default NewFolderModal;
