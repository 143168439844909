import React, { Component } from "react";

class UploadBox extends Component {
  state = {
    dragging: false,
  };

  _isMounted = false;

  dropRef = React.createRef();

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      let div = this.dropRef.current;
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: true });
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  render() {
    return (
      <React.Fragment>
        <div
          ref={this.dropRef}
          className={"uploadDropBox dropBoxDragging" + this.state.dragging}
        >
          Drop image here
        </div>
      </React.Fragment>
    );
  }
}

//============================ UI RENDER LOGIC =============================
//==========================================================================
//==========================================================================

export default UploadBox;
