import React, { Component } from "react";
import { toast } from "react-toastify";
// import axios from "axios";
import { register } from "../../services/userService";
import Alert from "react-bootstrap/Alert";
import {Container} from "react-bootstrap";

class Register extends Component {
  state = {
    newUser: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      registrationCode: ""
    },
    success: false
  };

  handleSubmitRegistration = async e => {
    e.preventDefault();

    if (this.state.newUser.password !== this.state.newUser.confirmPassword) {
      toast.error("Passwords do not match!");
      return null;
    }

    try {
      const { data: response } = await register(this.state.newUser);
      console.log(response);
      if (response.success) {
        //toast.info("Registration successful. Please check your email for ");
        this.setState({ success: true });
      } else {
        toast.error(response.message);
        //alert("Login failed");
      }
    } catch (error) {
      toast.error("Some crazy registration error occured!: ", error);
    }
  };

  handleInput = e => {
    const newUser = { ...this.state.newUser };
    newUser[e.target.name] = e.target.value;
    this.setState({ newUser });
  };

  render() {
    return (
      <Container>
      <React.Fragment>
        {!this.state.success && (
          <React.Fragment>
            <h1>Register</h1>
            <form onSubmit={this.handleSubmitRegistration}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  autoFocus
                  value={this.state.newUser.email}
                  onChange={this.handleInput}
                  name="email"
                  type="text"
                  className="form-control"
                  id="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  value={this.state.newUser.username}
                  onChange={this.handleInput}
                  name="username"
                  type="text"
                  className="form-control"
                  id="username"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  value={this.state.newUser.password}
                  onChange={this.handleInput}
                  name="password"
                  type="password"
                  className="form-control"
                  id="password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm password</label>
                <input
                  value={this.state.newUser.confirmPassword}
                  onChange={this.handleInput}
                  name="confirmPassword"
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                />
              </div>
              <div className="form-group">
                <label htmlFor="registrationCode">Registration Code</label>
                <input
                  value={this.state.newUser.registrationCode}
                  onChange={this.handleInput}
                  name="registrationCode"
                  type="text"
                  className="form-control"
                  id="registrationCode"
                />
              </div>
              <button className="btn btn-primary">Register</button>
            </form>
          </React.Fragment>
        )}
        {this.state.success && (
          <Alert variant="success">
            <Alert.Heading>Welcome to the club!</Alert.Heading>
            <p>
              Congratulations! Your registration was successful. Please check
              your email for a confirmation message and be sure to confirm your
              address before logging in.
            </p>
            <hr />
            <p className="mb-0">
              Be aware that your confirmation email may be in the SPAM folder,
              because its coming from an unvarified email address. This problem
              will be fixed in the future!
            </p>
          </Alert>
          // <div>

          // </div>
        )}
      </React.Fragment>
      </Container>
    );
  }
}

export default Register;
