import React, { Component } from "react";
import { Form, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createDocument,
  updateDocument,
  uploadImage,
} from "../../services/cmsService";
import { parseHeadings } from "./subfunctions/ParseHeadings";
import cloneDeep from "lodash/cloneDeep";
import ControlBar from "./controlBar";
import UtilityBar from "./utilityBar";
import FolderBox from "./folderBox";
import ParseChunk from "../cms/parseChunk";
import BookBox from "./bookBox";
import ImageBox from "./ImageBox";
import TableOfContents from "./subcomponents/TableOfContents";
import "../../css/documentHandler.scss";
import "../../css/utilityBar.css";
//import { uploadImage } from "../../services/cmsService";

class HandleDocument extends Component {
  state = {
    elementArray: [],
    showHideTextInput: false,
    utilityBarState: { active: false, editActive: false },
    document: null,
    book: null,
    chapters: null,
    imageReference: null,
    folderBoxDisabled: false,
    selectedFolder: null,
    folderContentUpdate: null,
    textInput: "",
    headings: null,
    dragTarget: null,
  };

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  resetState = (state) => {
    this.setState(state);
  };

  loadDocument = (document, content) => {
    let elementArray = [].concat(content);

    for (var i = 0; i < elementArray.length; i++) {
      elementArray[i].settings = JSON.parse(elementArray[i].settings);
      if (elementArray[i].settings.headingLevel) {
        //Parse strings to ints
        elementArray[i].settings.headingLevel = parseInt(
          elementArray[i].settings.headingLevel
        );
      }
      elementArray[i].changed = false;
    }

    elementArray.sort(function (a, b) {
      return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
    });

    //document.type = "document";
    document.newDocument = false;
    document.changed = false;
    //console.log(document.ToC);
    if (document.toC === null) {
      document.toC = false;
      document.changed = true;
    }
    if (document.renderTitle === null) {
      document.renderTitle = true;
      document.changed = true;
    }
    // document.toC = false;
    // document.renderTitle = true;
    //let headings = null;
    let headings = document.toC ? parseHeadings(elementArray) : null;

    console.log("headings: ", headings);

    this.setState({
      folderBoxDisabled: false,
      document,
      elementArray,
      headings,
      utilityBarState: this.returnEmptyUtilityBar(),
    });
  };

  loadChapter = (chapter, content) => {
    let elementArray = [].concat(content);

    for (var i = 0; i < elementArray.length; i++) {
      elementArray[i].settings = JSON.parse(elementArray[i].settings);
      elementArray[i].changed = false;
    }

    elementArray.sort(function (a, b) {
      return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
    });

    //document.type = "chapter";
    document.newDocument = false;
    document.changed = false;

    //console.log(document.ToC);

    this.setState({
      document,
      elementArray,
      utilityBarState: this.returnEmptyUtilityBar(),
    });
  };

  loadBook = (book, chapters) => {
    this.setState({ book, chapters, folderBoxDisabled: false });
  };

  loadImage = (imageReference) => {
    this.setState({ imageReference: imageReference });
  };

  closeBox = (target) => {
    if (target === "controlBar") {
      this.setState({
        folderBoxDisabled: false,
        document: null,
        elementArray: [],
        textInput: "",
        showHideTextInput: false,
      });
    }
    if (target === "bookBox") {
      this.setState({ folderBoxDisabled: false, book: null, chapters: null });
    }
    if (target === "imageBox") {
      this.setState({ folderBoxDisabled: false, imageReference: null });
    }
  };
  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  uploadImage = async (formData) => {
    const { data: response } = await uploadImage(formData);

    if (response && response.success) {
      //console.log("Image uploaded!");
      //let folderContents
      this.setState({
        imageReference: response.payload.imageReference,
        folderContentUpdate: response.payload.imageReference,
      });
    }
  };

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  createDocument = async () => {
    var parsedData = this.parseContent();
    this.state.document.content = parsedData.content;
    //console.log(this.state.document);
    const { data: response } = await createDocument(this.state.document);

    if (response && response.success) {
      toast("Document created successfully!");
      this.setState({ folderContentUpdate: response.document });
    } else {
      toast.error("An error occured: " + response.message);
    }
  };

  updateDocument = async () => {
    var parsedData = this.parseContent().content;
    let parsedContent = [];

    for (var i in parsedData) {
      if (parsedData[i].changed || parsedData[i].original)
        parsedContent.push(parsedData[i]);
    }

    //this.state.document.content = parsedContent;

    const { data: response } = await updateDocument(
      this.state.document,
      parsedContent
    );

    if (response && response.success) {
      toast("Document updated successfully!");
      //TODO: reset change
    } else {
      toast.error("An error occured: " + response.message);
    }
  };

  parseText = () => {
    const copyText = this.state.textInput;
    const textArray = copyText.split("\n");
    let elementArray = [];
    //let lastElement = null;

    //TODO: Single source of truth for new element; see doubling up in utilitybar
    for (var i = 0; i < textArray.length; i++) {
      elementArray.push({
        id: i,
        sequenceNumber: i,
        type: "paragraph",
        settings: { paragraphIndentLevel: 0, headingLevel: 1, imageName: "" },
        content: textArray[i],
        original: true,
        // saved: false,
      });
      //lastElement = i;
    }
    this.setState({ elementArray: elementArray });
  };

  // TESTAddElement = () => {
  //   let elementArray = [].concat(this.state.elementArray);
  //   elementArray.push({
  //     id: elementArray.length,
  //     sequenceNumber: elementArray.length,
  //     type: "paragraph",
  //     settings: { paragraphIndentLevel: 0, headingLevel: 1, imageName: "" },
  //     content: "Upsert test",
  //     original: true,
  //   });
  //   this.setState({ elementArray });
  // };

  returnEmptyUtilityBar = () => {
    return {
      active: false,
      editActive: false,
      activeElementId: null,
      //   activeType: null,
    };
  };

  parseContent = () => {
    var content = cloneDeep(this.state.elementArray);

    //strip and clean content
    var cleanedContent = [];
    for (var index in content) {
      if (content[index].content !== "") {
        content[index].content = content[index].content.trim();
        cleanedContent.push(content[index]);
      }
    }

    for (var i = 0; i < cleanedContent.length; i++) {
      cleanedContent[i].sequenceNumber = i;
      if (cleanedContent[i].original) cleanedContent[i].id = "";
      //cleanedContent[i].id = "";
      //cleanedContent[i].parentId = "";
      cleanedContent[i].settings = JSON.stringify(cleanedContent[i].settings);
    }

    return { content: cleanedContent };
  };

  addNewItem = (type, parentFolder) => {
    if (type === "1") {
      let newDoc = {
        folderId: parentFolder.id,
        title: "",
        description: "",
        author: "",
        contentType: "document",
        newDocument: true,
        ToC: false,
      };
      this.setState({ document: newDoc });
    }
    if (type === "2") {
      let newImage = {
        fileName: "",
        fileData: null,
        contentType: "imageReference",
        new: true,
      };

      this.setState({ imageReference: newImage });
      //console.log("no problemo");
    }

    if (type === "7") {
      let newBook = {
        contentType: "book",
        title: "default",
        author: "",
        new: true,
      };
      this.setState({ book: newBook, chapters: [] });
    }
  };

  //========================  CLICK HANDLES  =================================
  //==========================================================================
  //==========================================================================

  handleShowHideClick = () => {
    let { showHideTextInput } = this.state;
    showHideTextInput = !showHideTextInput;
    this.setState({ showHideTextInput });
  };

  handleElementClick = (id) => {
    const element = this.state.elementArray.find((x) => x.id === id);
    const utilityBarState = { ...this.state.utilityBarState };
    utilityBarState.active = true;
    utilityBarState.editActive = false;
    utilityBarState.activeElementId = id;
    utilityBarState.activeType = element.type;
    this.setState({ utilityBarState: utilityBarState });
  };

  handleTextAreaInput = (e) => {
    let textInput = { ...this.state.textInput };
    textInput = e.target.value;
    this.setState({ textInput });
  };

  onElementDrop = () => {
    console.log("element dropped!");
  };

  //===========================  RENDER  =====================================
  //==========================================================================
  //==========================================================================

  render() {
    const {
      showHideTextInput,
      elementArray,
      document,
      headings,
      book,
      chapters,
      imageReference,
      folderBoxDisabled,
      selectedFolder,
    } = this.state;

    return (
      <Container>
        <p>{this.state.dragTarget && this.state.dragTarget.id}</p>
        <FolderBox
          disabled={folderBoxDisabled}
          // newDocument={(parentFolder) => this.createNewDocument(parentFolder)}
          addNew={(type, parentFolder) => this.addNewItem(type, parentFolder)}
          loadDocument={(document, content) =>
            this.loadDocument(document, content)
          }
          resetState={this.resetState}
          folderContentUpdate={this.state.folderContentUpdate}
          loadBook={(book, chapters) => this.loadBook(book, chapters)}
          loadImage={(imageReference) => this.loadImage(imageReference)}
        ></FolderBox>
        <p></p>
        {book && (
          <BookBox
            closeBox={() => this.closeBox("bookBox")}
            book={book}
            chapters={chapters}
            folderId={selectedFolder.id}
            loadChapter={(document, content) =>
              // this.loadChapter(chapter, content)
              this.loadDocument(document, content)
            }
            resetState={this.resetState}
            dragTarget={this.state.dragTarget}
          ></BookBox>
        )}
        <p></p>
        {imageReference && (
          <ImageBox
            imageReference={imageReference}
            closeBox={() => this.closeBox("imageBox")}
            // folderId={this.state.selected}
            folderId={selectedFolder}
            resetState={this.resetState}
            selectedFolder={selectedFolder}
            uploadImage={(formData) => this.uploadImage(formData)}
          ></ImageBox>
        )}
        <p></p>
        <div className="text-center">
          <button className="btn-primary" onClick={this.handleShowHideClick}>
            Show/Hide Text Area
          </button>
        </div>
        <p></p>
        {showHideTextInput && (
          <React.Fragment>
            <Form>
              <Form.Group controlId="inputtest">
                <Form.Label>Test Input</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={this.handleTextAreaInput}
                  rows="12"
                  value={this.state.textInput}
                />
              </Form.Group>
            </Form>
            <div className="text-center mb-4">
              <button onClick={this.parseText} className="btn-primary">
                Parse text
              </button>
            </div>
          </React.Fragment>
        )}
        <p></p>
        {document && (
          <ControlBar
            closeBox={() => this.closeBox("controlBar")}
            document={document}
            content={elementArray}
            resetState={this.resetState}
            createDocument={this.createDocument}
            updateDocument={this.updateDocument}
          ></ControlBar>
        )}
        <p></p>
        {elementArray.length !== 0 && (
          <div className="stickyDiv">
            <UtilityBar
              resetState={this.resetState}
              utilityBarState={this.state.utilityBarState}
              elementArray={elementArray}
              dragTarget={this.state.dragTarget}
            ></UtilityBar>
          </div>
        )}
        {document && document.renderTitle && (
          <React.Fragment>
            <div className="documentTitle mb-3">{document.title}</div>
          </React.Fragment>
        )}
        {document && headings && (
          <div className="documentToC">
            <TableOfContents headings={headings} />
          </div>
        )}
        <p></p>
        <div>
          {elementArray.map((element, index) => (
            <div key={"holdingDiv" + index} className="holdingDiv">
              {ParseChunk(
                element,
                index,
                headings,
                this.handleElementClick,
                this.parseActiveState(element),
                this.onElementDrop
              )}
            </div>
          ))}
        </div>
        {/* <div className="mt-5">
          <button
            onClick={() => this.submitOrUpdateChapter("submit")}
            className="btn-primary"
          >
            Submit Chapter
          </button>
          <button
            onClick={() => this.submitOrUpdateChapter("update")}
            className="btn-primary"
          >
            Update/Replace Chapter
          </button>
        </div> */}
        {/* <div style={{ height: "500px" }}></div> */}
      </Container>
    );
  }

  //==========================  UI RENDER LOGIC  =============================
  //==========================================================================
  //==========================================================================

  parseActiveState = (element) => {
    const returnClass =
      element.id === this.state.utilityBarState.activeElementId
        ? "activeElement"
        : "inactiveElement";
    return returnClass;
  };
}

export default HandleDocument;
