import axios from "axios";
import {
    toast
} from "react-toastify";

axios.interceptors.response.use(response => {
    //console.log("Interceptor success: ", response);
    const localAccessToken = localStorage.getItem("accessToken");
    const responseAccessToken = response.headers["token"];
    // console.log("Intercepted access token: ", response.headers["token"]);
    // console.log("Intercepted refresh token: ", response.headers["refreshtoken"]);
    if (responseAccessToken && localAccessToken !== responseAccessToken) {
        console.log("Access token has changed!", responseAccessToken);
        if (responseAccessToken.length > 32) {
            localStorage.setItem("accessToken", responseAccessToken);
        }
    }
    return response;
}, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    console.log("intercept: ", error);

    if (error.response && error.response.status === 401) {
        toast.error("Unauthorized! Please login again.");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        window.location = "/";
    }

    if (error.response && error.response.status === 400) {
        toast.error("Bad request!");

    }

    if (error.response && error.response.status === 404) {
        toast.error("Resource not found! 404 Error");

    }

    if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
        console.log(Promise);

    }

    if (!expectedError) {
        //For unexpected errors
        console.log(error);
        toast.error("An unexpected error occured!");
    }
    return Promise;
    //return Promise.reject(error);
});

export default {
    get: (url) => axios.get(url, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    }),
    post: (url, data) => axios.post(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    }),
    upload: (url, data) => axios.post(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken"),
            // 'Content-Type': 'multipart/form-data'
            'Accept': 'application/json'
        }
    }),
    update: (url, data) => axios.put(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    }),
    delete: (url, data) => axios.delete(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    })

};