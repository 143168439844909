import React, { Component } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import TextInput from "./subcomponents/textInput";
import TextArea from "./subcomponents/TextArea";
import CheckInput from "./subcomponents/CheckInput";

class ControlBar extends Component {
  state = {};

  resetState = (state) => {
    this.props.resetState(state);
  };

  // handleForgetDocument = () => {
  //   //TODO: prompt user to confirm
  //   //this.props.resetState({ document: null });
  //   this.props.closeBox();
  // };

  handleCloseControlBar = () => {
    //this.props.resetState({ document: null });
    this.props.closeBox();
  };

  resetSequence = () => {
    let contentArray = [].concat(this.props.content);
    for (var i in contentArray) {
      contentArray[i].sequenceNumber = i;
      contentArray[i].changed = true;
    }
    this.resetState({ elementArray: contentArray });
  };

  render() {
    const { document, content } = this.props;
    return (
      <React.Fragment>
        <Container className="utilityBox">
          <Row className="justify-content-end">
            <Button
              size="sm"
              variant="danger"
              onClick={this.handleCloseControlBar}
            >
              X
            </Button>
          </Row>
          <Row className=" justify-content-center mb-2">
            <Col>
              <TextInput
                className="mr-3"
                resetState={this.resetState}
                target={document}
                property="title"
                label="Title"
              ></TextInput>
            </Col>
            <Col>
              <TextInput
                resetState={this.resetState}
                target={document}
                property="author"
                label="Author"
              ></TextInput>
            </Col>
          </Row>
          <Row className="justify-content-center mb-3">
            <Col>
              <TextArea
                resetState={this.resetState}
                target={document}
                property="description"
                label="Description"
              ></TextArea>
            </Col>

            {/* <TextInput
              resetState={this.resetState}
              target={document}
              property="description"
              label="Description"
            ></TextInput> */}
            {/* <span className="ml-3">{"Id: " + document.id}</span> */}
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <CheckInput
                resetState={this.resetState}
                target={document}
                property="toC"
                label="Render table of contents?"
              ></CheckInput>
            </Col>
            <Col md="auto">
              <CheckInput
                resetState={this.resetState}
                target={document}
                property="renderTitle"
                label="Render title?"
              ></CheckInput>
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            {(content.length === 0 || document.title === "") && (
              <Alert className="text-center" variant="warning">
                {content.length === 0 && "This document has no content!"}
                {document.title === "" && " This document has no title!"}
              </Alert>
            )}
          </Row>
          <Row className="justify-content-center mb-2">
            {!document.newDocument && (
              <Button
                variant="outline-success"
                className="mr-2"
                disabled={!this.parseChangeStatus}
                onClick={this.props.updateDocument}
              >
                Update Document
              </Button>
            )}
            {document.newDocument && (
              <Button
                variant="outline-primary"
                className="mr-2"
                onClick={this.props.createDocument}
                disabled={content.length === 0 || document.title === ""}
              >
                Create Document
              </Button>
            )}
            <Button
              variant="outline-secondary"
              className="mr-2"
              onClick={this.resetSequence}
            >
              Reset sequence
            </Button>
          </Row>
          {/* <Button
            variant="outline-danger"
            size="sm"
            onClick={this.handleForgetDocument}
          >
            Forget new document
          </Button>
          <Row className="justify-content-right"></Row> */}
        </Container>
      </React.Fragment>
    );
  }

  parseChangeStatus = () => {
    if (this.document.changed) return true;
    for (var i in this.document.content) {
      if (this.document.content[i].changed) return true;
    }
    return false;
  };
}

export default ControlBar;
