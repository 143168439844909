import React, { Component } from "react";

class TableOfContents extends Component {
  state = {};

  Goto = (location) => {
    document.getElementById(location).scrollIntoView(true);
  };

  render() {
    const headings = this.props.headings;

    return (
      <React.Fragment>
        {Object.keys(headings).map((key) => (
          // <div
          //   key={"TOCHolder" + headings[key].id}
          //   className={this.parseTOCStyle(headings[key]) + " TOCInternal"}
          // >
          //   <a
          //     //key={"TOC" + headings[key].id}
          //     className={this.parseTOCStyle(headings[key]) + " TOCInternal"}
          //     href={"#element" + headings[key].id}
          //   >
          //     {headings[key].levelText + " " + headings[key].name}
          //   </a>
          // </div>
          <div key={"TOCHolder" + headings[key].id} className="TOCHolder">
            <div
              onClick={() => this.Goto("element" + headings[key].id)}
              className={
                this.parseTOCStyle(headings[key]) + " TOCInternal TOCHover "
              }
            >
              {headings[key].levelText + " " + headings[key].name}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }

  parseTOCStyle = (heading) => {
    let className = "";
    let topPadding = heading.level === 1 ? 2 : 1;
    //className += "h" + (heading.level + 2) + " ";
    className += "pl-" + (heading.level - 1) * 4 + " ";
    className += "pt-" + topPadding + " ";
    //console.log(className);
    return className;
  };
}

export default TableOfContents;
