import React, { Component } from "react";
import Logo from "../../../img/logo.png";
//import PDLogo from "../../../img/PDLogo.jpg";

import "../../../css/3dAnimation.css";

class TestAnimation extends Component {
  state = {};

  _isMounted = false;

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  render() {
    return (
      <React.Fragment>
        <div className="scene3d" id="sce6">
          <div className="object3d" id="obj6">
            <img className="face3d smallLogo" alt="lo" src={Logo} id="im2E" />
            <img className="face3d smallLogo" alt="lo" src={Logo} id="im2F" />
            <img className="face3d smallLogo" alt="lo" src={Logo} id="im30" />
            <img className="face3d smallLogo" alt="lo" src={Logo} id="im31" />
            <img className="face3d smallLogo" alt="lo" src={Logo} id="im32" />
            <img className="face3d smallLogo" alt="lo" src={Logo} id="im33" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

//============================ UI RENDER LOGIC =============================
//==========================================================================
//==========================================================================

export default TestAnimation;
