import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter
} from "react-router-dom";



import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import App from "./components/app";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(< BrowserRouter >
    <App> </App> </BrowserRouter>);

/*ReactDOM.render( < BrowserRouter >
  <App> </App> </BrowserRouter>,
  document.getElementById("root")
);*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();