import React, { Component } from "react";
//import React, { Component, Fragment } from "../";
class Portfolio extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Portfolio</h1>
        <div>No content here, yet. Sorry!</div>
      </React.Fragment>
    );
  }
}

export default Portfolio;
