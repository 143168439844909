import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { adminHandler } from "../../../services/adminService";

class AdminDataHandler extends Component {
  state = { flushData: false };

  handleAdminSelect = async (target, command) => {
    const { data: result } = await adminHandler(target, command, null);
    if (result && result.success) {
      this.setState({ target: target, payload: result.payload });
      if (this.state.flushData) {
        this.props.flushData(result.payload);
      }
    }
  };

  handleAdminGetUsers = async () => {
    const result = await adminHandler("Accounts", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "Accounts",
      payload: payload,
    });
  };

  handleAdminGetRefreshTokens = async () => {
    const result = await adminHandler("RefreshTokens", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "RefreshTokens",
      payload: payload,
    });
  };

  handleAdminGetEmailTokens = async () => {
    const result = await adminHandler("EmailTokens", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "EmailTokens",
      payload: payload,
    });
  };

  handleAdminForgotPasswordTokens = async () => {
    const result = await adminHandler("ForgotPasswordTokens", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "ForgotPasswordTokens",
      payload: payload,
    });
  };

  handlePollRO = async () => {
    const result = await adminHandler("PollRankOrderings", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "PollRankOrderings",
      payload: payload,
    });
  };

  handlePollROChoices = async () => {
    const result = await adminHandler(
      "PollRankOrderingChoices",
      "GetAll",
      null
    );
    const { payload } = result.data;

    this.setState({
      target: "PollRankOrderingChoices",
      payload: payload,
    });
  };

  handleGetBooks = async () => {
    const result = await adminHandler("Books", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "Books",
      payload: payload,
    });
  };

  handleGetChapters = async () => {
    const result = await adminHandler("Chapters", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "Chapters",
      payload: payload,
    });
  };

  handleGetDocuments = async () => {
    const result = await adminHandler("Documents", "GetAll", null);
    const { payload } = result.data;

    this.setState({
      target: "Documents",
      payload: payload,
    });
  };

  deleteItem = async (itemId) => {
    const result = await adminHandler(this.state.target, "Delete", itemId);

    if (result.data.success) {
      let tempPayload = this.state.payload.filter((item) => item.id !== itemId);
      this.setState({ payload: tempPayload });
    }
  };

  handleFlushChange = (e) => {
    this.setState({ flushData: !this.state.flushData });
  };

  render() {
    //const { user } = this.props;
    //const userClaims = user !== undefined ? user.claims : "";
    const { payload } = this.state;

    return (
      <div>
        {/* <button onClick={this.handleAdminGetUsers}>AdminH: GetUsers</button>
        <button onClick={this.handleAdminGetRefreshTokens}>
          AdminH: Get RefTokens
        </button>
        <button onClick={this.handleAdminGetEmailTokens}>
          AdminH: Get EmailTokens
        </button>
        <button onClick={this.handleAdminForgotPasswordTokens}>
          AdminH: Get ForgotPasswordTokens
        </button>
        <button onClick={this.handlePollRO}>
          AdminH: Get PollRankOrderings
        </button>
        <button onClick={this.handlePollROChoices}>
          AdminH: Get PollRankOrderingChoices
        </button>
        <button onClick={this.handleGetBooks}>AdminH: Get Books</button>
        <button onClick={this.handleGetChapters}>AdminH: Get Chapters</button> */}
        <button onClick={() => this.handleAdminSelect("Accounts", "GetAll")}>
          AdminH: GetUsers
        </button>
        <button
          onClick={() => this.handleAdminSelect("RefreshTokens", "GetAll")}
        >
          AdminH: Get RefTokens
        </button>
        <button onClick={() => this.handleAdminSelect("EmailTokens", "GetAll")}>
          AdminH: Get EmailTokens
        </button>
        <button
          onClick={() =>
            this.handleAdminSelect("ForgotPasswordTokens", "GetAll")
          }
        >
          AdminH: Get ForgotPasswordTokens
        </button>
        <button
          onClick={() => this.handleAdminSelect("PollRankOrderings", "GetAll")}
        >
          AdminH: Get PollRankOrderings
        </button>
        <button
          onClick={() =>
            this.handleAdminSelect("PollRankOrderingChoices", "GetAll")
          }
        >
          AdminH: Get PollRankOrderingChoices
        </button>
        <button onClick={() => this.handleAdminSelect("Books", "GetAll")}>
          AdminH: Get Books
        </button>
        <button onClick={() => this.handleAdminSelect("Chapters", "GetAll")}>
          AdminH: Get Chapters
        </button>
        <button onClick={() => this.handleAdminSelect("Documents", "GetAll")}>
          AdminH: Get Documents
        </button>
        <button
          onClick={() => this.handleAdminSelect("ContentChunks", "GetAll")}
        >
          AdminH: Get ContentChunks
        </button>
        <button onClick={() => this.handleAdminSelect("Folders", "GetAll")}>
          AdminH: Get Folders
        </button>
        <button
          onClick={() => this.handleAdminSelect("FolderContents", "GetAll")}
        >
          AdminH: Get FolderContents
        </button>
        <p></p>
        <div className="align-center">
          <Form.Check
            type="checkbox"
            value={this.state.flushData}
            onChange={this.handleFlushChange}
            label="Flush JSON data?"
          />
        </div>
        <p></p>
        {this.state.payload && (
          <React.Fragment>
            <table className="table table-striped table-hover table-sm table-responsive">
              <thead>
                <tr key="header">
                  <td>#</td>
                  {Object.keys(payload[0]).map((key) => (
                    <td key={key}>{key}</td>
                  ))}
                  <td>Delete</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(payload).map((itemRow, index) => (
                  <tr key={payload[itemRow]["id"]}>
                    <td>{index}</td>
                    {Object.keys(payload[itemRow]).map((item, index2) => (
                      <td key={payload[itemRow]["id"] + index2}>
                        {this.parseItem(item, payload[itemRow][item], index2)}
                      </td>
                    ))}
                    <td>
                      <button
                        onClick={() => this.deleteItem(payload[itemRow]["id"])}
                        className="btn-small btn-danger"
                      >
                        -
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
    );
  }
  parseItem = (key, item, index) => {
    // if (key === "id") {
    //   return index;
    // }
    if (key === "validUntil") {
      let unix_timestamp = item;
      var date = new Date(unix_timestamp * 1000);
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var formattedTime = hours + ":" + minutes.substr(-2);
      return formattedTime;
    }
    return "" + item;
  };
}

export default AdminDataHandler;
