import http from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "cms/";
console.log(apiEndpoint);
//BOOKS

export function createChapter(newChapter, elementArray) {
    return http.post(apiEndpoint + "createChapter", {
        BookId: newChapter.parentId,
        Title: newChapter.title,
        SequenceNumber: newChapter.sequenceNumber,
        Content: elementArray
    });
}

export function updateChapter(newChapter, elementArray) {
    return http.post(apiEndpoint + "updateChapter", {
        BookId: newChapter.parentId,
        Title: newChapter.title,
        SequenceNumber: newChapter.sequenceNumber,
        Content: elementArray,
        ChapterId: newChapter.id
    });
}

export function updateChapters(newChapters, bookId) {
    return http.post(apiEndpoint + "updateChapters", {
        "BookId": bookId,
        "NewChapters": newChapters
    });
}

export function createBook(book, chapterIds, folderId) {
    return http.post(apiEndpoint + "createBook", {
        "FolderId": folderId,
        "Author": book.author,
        "Title": book.title,
        "ChapterIds": chapterIds
    });
}

export function getBook(id) {
    return http.get(apiEndpoint + "getBook/" + id);
}

export function updateBook(book) {
    return http.post(apiEndpoint + "updateBook", {
        "Book": book
    });
}

export function getBooks() {
    return http.get(apiEndpoint + "getBooks/");
}

// export function getChapter(id) {
//     return http.get(apiEndpoint + "getChapter/" + id);
// }

export function deleteChapter(id) {
    return http.post(apiEndpoint + "deleteChapter/" + id);
}

//FOLDERS

export function getFolders() {
    return http.get(apiEndpoint + "getFolders/");
}

export function createFolder(newFolder) {
    return http.post(apiEndpoint + "createFolder", newFolder);
}

export function getFolderContents(id) {
    return http.get(apiEndpoint + "getFolderContents/" + id);
}

export function deleteFolder(id) {
    return http.post(apiEndpoint + "deleteFolder/" + id);
}

export function deleteContentChunk(id) {
    return http.post(apiEndpoint + "deleteContentChunk/" + id);
}


//DOCUMENTS

// export function getCollection(id) {
//     return http.get(apiEndpoint + "getCollection/" + id);
// }

// export function getCollections() {
//     return http.get(apiEndpoint + "getCollections/");
// }

export function createDocument(document) {
    return http.post(apiEndpoint + "createDocument", document);
}

export function updateDocument(document, content) {
    return http.post(apiEndpoint + "updateDocument", {
        "Document": document,
        "Content": content
    });
}

export function getDocument(id) {
    return http.get(apiEndpoint + "getDocument/" + id);
}

export function deleteDocument(id) {
    return http.post(apiEndpoint + "deleteDocument/" + id);
}


//Image

export function uploadImage(data) {


    // return fetch(apiEndpoint + "uploadImage", {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //         'Accept': 'application/json',
    //         "Authorization": localStorage.getItem("accessToken"),
    //         "Refresh": localStorage.getItem("refreshToken")
    //     },
    //     body: file
    // })

    // return http.upload(apiEndpoint + "uploadImage", {
    //     "FolderId": folderId,
    //     "FileName": fileName,
    //     "File": file
    // });

    return http.upload(apiEndpoint + "uploadImage", data);
}