import React, { Component } from "react";
import { toast } from "react-toastify";
import { Card, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import BannerImage from "../img/Home_Banner3.jpg";
import PDLogo from "../img/PDLogo.jpg";
import TestAnimation from "../components/accounts/admin/testAnimation";

class Home extends Component {
  state = {};

  componentDidMount() {
    const signOut = localStorage.getItem("signout");
    if (signOut === "true") {
      toast.error("Your session expired. Please login again.");
      localStorage.removeItem("signout");
    }
  }

  render() {
    return (
      <Container>
        <div className="bannerImage mb-3">
          <img
            alt="home banner"
            className="mt-3"
            src={BannerImage}
            width="100%"
          ></img>
        </div>

        <Card className="text-center">
          <Card.Header as="h2">Welcome</Card.Header>
          <Card.Body>
            <Card.Text style={{ fontSize: "25px" }}>
              You've reached the personal website of James Danielsen. This site
              is constantly under construction as I adapt to the times.
              Take a look at some of the items below. I've recently digitised my
              thesis!
            </Card.Text>
            {/* <Button variant="primary" href="/voting">
              Go to voting
            </Button> */}
          </Card.Body>
        </Card>
        <p></p>
        <div className="card-columns">
          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Thesis</Card.Header>
            {/* <Card.Img
              variant="top"
              src={PDLogo}
              width="60px"
              alt="pyramidal democracy logo"
            /> */}
            <div className="text-center">
              <img
                alt="pyramidal democracy logo"
                src={PDLogo}
                width="60%"
                className="m-2"
              />
            </div>
            <Card.Body>
              <Card.Title>Pyramidal Deliberative Democracy</Card.Title>
              <Card.Text>
                I've digitised my PhD thesis! Want to take a look?
              </Card.Text>
              <div className="text-center">
                <Button variant="outline-success" href="/thesis">
                  Make it so
                </Button>
              </div>
            </Card.Body>
          </Card>

          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Paper</Card.Header>
            {/* <Card.Img variant="top" src={PDLogo} width="50px" /> */}
            <Card.Body>
              <Card.Title>The Storyteller</Card.Title>
              <Card.Text>
                Once upon a time, a machine began telling us the stories of our
                lives…and humanity lived happily ever after
              </Card.Text>
              <div className="text-center mb-3">
                <Button
                  variant="outline-success"
                  className="m-2"
                  onClick={() =>
                    window.open(
                      "https://policyreview.info/essays/scifi/storyteller"
                    )
                  }
                >
                  Read (external)
                </Button>
                <Button
                  variant="outline-secondary"
                  href="/papers/5e944d3e79cddf0a60f2b041"
                >
                  Read (internal)
                </Button>
              </div>
              <Card.Footer>
                <small className="text-muted">8 minute read</small>
              </Card.Footer>
            </Card.Body>
          </Card>

          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Paper</Card.Header>
            {/* <Card.Img variant="top" src={PDLogo} width="50px" /> */}
            <Card.Body>
              <Card.Title>Pyramidal Deliberative Networks</Card.Title>
              <Card.Text>
                A paper that lays out the technical schema for a solution to the
                problem of scale in deliberative democracy...
              </Card.Text>

              <Button
                variant="outline-success"
                href="/papers/5e9f0ab20142a41face4dcc6"
                className="mb-3"
              >
                Read on, Macduff
              </Button>

              <Card.Footer>
                <small className="text-muted">25 minute read</small>
              </Card.Footer>
            </Card.Body>
          </Card>

          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Coding / Animation</Card.Header>
            <Card.Body style={{ overflow: "hidden" }}>
              <TestAnimation></TestAnimation>
            </Card.Body>
          </Card>

          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Paper</Card.Header>
            {/* <Card.Img variant="top" src={PDLogo} width="50px" /> */}
            <Card.Body>
              <Card.Title>
                Maximising Capacity factor for Sustainability
              </Card.Title>
              <Card.Text>
                A paper suggesting that pyramidal deliberative networks might
                maximise our capacity to reengineer the planet for greater
                sustainability and equality...
              </Card.Text>

              <Button
                variant="outline-success"
                href="/papers/5eab2fcecbcb1e5930ef64f0"
                className="mb-3"
              >
                Engage!
              </Button>

              <Card.Footer>
                <small className="text-muted">40 minute read</small>
              </Card.Footer>
            </Card.Body>
          </Card>

          <Card className="p-3 fadeIn cardHover">
            <blockquote className="blockquote mb-0 card-body">
              <p>
                The Art of Biography <br /> Is Different from Geography <br />{" "}
                Geography is about Maps <br /> But Biography is about Chaps
              </p>
              <footer className="blockquote-footer">
                <small className="text-muted">
                  Edmund Clerihew Bentley -{" "}
                  <cite title="Source Title">
                    Biography for Beginners (1905) Introduction
                  </cite>
                </small>
              </footer>
            </blockquote>
          </Card>

          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Coding</Card.Header>
            {/* <Card.Img variant="top" src={PDLogo} width="50px" /> */}
            <Card.Body>
              <Card.Title>Chess</Card.Title>
              <Card.Text>
                Check out my chess game. Coded in javascript especially for you.
                Play hotseat with a friend, or, better yet, an enemy!
              </Card.Text>
              <div className="text-center mb-2">
                <Button
                  variant="outline-success"
                  // className="mr-3"
                  href="/chess/"
                >
                  Sample the pawn cocktails
                </Button>
              </div>
              {/* <Card.Footer>
                <small className="text-muted">8 minute read</small>
              </Card.Footer> */}
            </Card.Body>
          </Card>

          <Card className="text-center fadeIn cardHover">
            <Card.Header as="h6">Coding</Card.Header>
            <Card.Body>
              <Card.Title>Voting</Card.Title>
              <Card.Text>
                I've developed some voting functionality as part of my plans for
                world domination...
              </Card.Text>
              <div className="text-center mb-2">
                <Button
                  variant="outline-success"
                  // className="mr-3"
                  href="/voting/"
                >
                  Have your say
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    );
  }
}

export default Home;
