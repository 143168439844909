import React, { Component } from "react";

class Profile extends Component {
  state = {};

  render() {
    const { user } = this.props;
    const userClaims = user !== undefined ? user.claims : "";
    // const { payload } = this.state;

    return (
      <React.Fragment>
        <h1>Profile</h1>
        {userClaims.includes("SuperAdmin") && (
          <div>
            <p>This is a super admin area</p>
          </div>
        )}
        {!userClaims.includes("SuperAdmin") && (
          <p>This is a normal user area</p>
        )}
      </React.Fragment>
    );
  }
}

export default Profile;
