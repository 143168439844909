import React from "react";
import Heading from "./heading";

function ParseChunk(element, index, headings, clickHandler, extraClass) {
  clickHandler =
    clickHandler === null || clickHandler === undefined
      ? function () {}
      : clickHandler;

  extraClass =
    extraClass === null || extraClass === undefined ? "" : extraClass;

  // allowDrop = (ev) => {
  //   ev.preventDefault();
  // };

  if (element.type === "paragraph") {
    // if (!element.settings.paragraphIndentLevel) {
    //   console.log(element);
    //   element.settings.paragraphIndentLevel = 0;
    // }
    return (
      <p
        id={"element" + index}
        key={"element" + index}
        className={"paragraph documentText " + extraClass}
        onClick={() => clickHandler(element.id)}
        style={{
          marginLeft: element.settings.paragraphIndentLevel * 30,
        }}
      >
        {element.content}
      </p>
    );
  }

  if (element.type === "heading") {
    if (headings != null) {
      let anchorSuffix = headings[element.id] ? headings[element.id].id : "";
      let titlePrefix = headings[element.id]
        ? headings[element.id].levelText
        : "";
      return (
        // <a name={"element" + headings[element.id].id}>
        <a id={"element" + anchorSuffix} key={"element" + index}>
          <Heading
            //type={"h" + (Number(element.settings.headingLevel) + 1)}
            type={Number(element.settings.headingLevel)}
            id={"element" + index}
            className={
              "heading " +
              extraClass +
              " documentHeading" +
              element.settings.headingLevel
            }
            onClick={() => clickHandler(element.id)}
          >
            {/* {headings[element.id].levelText + " " + element.content} */}
            {titlePrefix + " " + element.content}
          </Heading>
        </a>
      );
    }
    if (headings === null) {
      return (
        <Heading
          type={"h" + (Number(element.settings.headingLevel) + 1)}
          id={"element" + index}
          key={"element" + index}
          className={"heading " + extraClass}
          onClick={() => clickHandler(element.id)}
        >
          {element.content}
        </Heading>
      );
    }
  }
  if (element.type === "divider") {
    return (
      <p
        id={"element" + index}
        key={"element" + index}
        className={"divider " + extraClass}
        onClick={() => clickHandler(element.id)}
      >
        {element.content}
      </p>
    );
  }
  if (element.type === "quote") {
    return (
      <blockquote
        id={"element" + index}
        key={"element" + index}
        className={"quote " + extraClass}
        onClick={() => clickHandler(element.id)}
      >
        {element.content}
      </blockquote>
    );
  }
  if (element.type === "caption") {
    return (
      <p
        id={"element" + index}
        key={"element" + index}
        className={"caption " + extraClass}
        onClick={() => clickHandler(element.id)}
      >
        {element.content}
      </p>
    );
  }
  if (element.type === "image") {
    return (
      <img
        // onDrop={(element) => dropFunction(element)}
        // onDragOver={(e) => {
        //   e.preventDefault();
        // }}
        id={"element" + index}
        key={"element" + index}
        className={"bookImage " + extraClass}
        onClick={() => clickHandler(element.id)}
        alt="alt text"
        // src={process.env.PUBLIC_URL + "/img/" + element.settings.imageName}
        src={"http://jamesdanielsen.com/uploads/" + element.settings.imageName}
      ></img>
    );
  }
  if (element.type === "table") {
    return (
      <div
        id={"element" + index}
        key={"element" + index}
        className={"text-center table-responsive " + extraClass}
        onClick={() => clickHandler(element.id)}
        dangerouslySetInnerHTML={{
          __html: element.content,
        }}
      ></div>
    );
  }
  if (element.type === "quoteblock") {
    return (
      <p
        id={"element" + index}
        key={"element" + index}
        className={"quoteblock " + extraClass}
        onClick={() => clickHandler(element.id)}
      >
        {element.content}
      </p>
    );
  }
}

export default ParseChunk;
