import React, { Component } from "react";
import AdminDataHandler from "./dataHandler";
import { Accordion, Card } from "react-bootstrap";
import Test from "./test";
import TestAnimation from "./testAnimation";

class Admin extends Component {
  state = {
    flushedData: null,
  };

  populateFlushedData = (data) => {
    this.setState({ flushedData: data });
  };

  render() {
    return (
      <React.Fragment>
        {/* DataHandler*/}
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Admin Data Handler
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <AdminDataHandler flushData={this.populateFlushedData} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {/* TEST */}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Test
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Test />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Animation
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <TestAnimation></TestAnimation>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <p></p>
        {this.state.flushedData && (
          <div>{JSON.stringify(this.state.flushedData)}</div>
        )}
        <div style={{ height: "500px" }}></div>
      </React.Fragment>
    );
  }
}

export default Admin;
