import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";

class About extends Component {
  state = {};
  render() {
    return (
      <Container>
        <p></p>
        <Card className="text-center mb-4">
          <Card.Header as="h4">About me</Card.Header>
          <Card.Body>
            <Card.Text>I'm James.</Card.Text>
          </Card.Body>
        </Card>
        <Card className="text-center mb-4">
          <Card.Header as="h4">About this website</Card.Header>
          <Card.Body>
            <Card.Text>
              This site is an ongoing experiment in design and coding. It's
              built with a Javascript-based React front-end, a C# .Net Core
              back-end, and a MongoDB persistence layer. Everything is built
              from scratch - no templates! The site is hosted using Amazon Web
              Services with Lambda. I'm in the process of rebuilding it using a functional 
              rather than a class methodology.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="text-center mb-4">
          <Card.Header as="h4">About voting</Card.Header>
          <Card.Body>
            <Card.Text>
              The voting functionality on this site is inspired by the{" "}
              {
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://whale.imag.fr/"
                >
                  Whale4 platform.
                </a>
                
              }
              <p>
              [Note: the voting functionality has been removed while I rebuild the site.]
              </p>
              
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default About;
