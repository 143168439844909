import React, { Component } from "react";
// import Head01 from "../../../img/SHead01.svg";
// import Head02 from "../../../img/SynappAvatar";
// import { MorphReplace } from "react-svg-morph";
import "../../../css/testArea.css";
import { uploadImage } from "../../../services/cmsService";
// import { Container, Row, Col } from "react-bootstrap";

//Avatar: 560 x 570

// class AvatarRenderBox extends React.Component {
//   render() {
//     return (
//       <React.Fragment>
//         <div className="imageBlender">
//           <img
//             className={"cropped " + this.state.bottom}
//             src={
//               this.state.directory +
//               "P1010" +
//               this.state.picNumberBottom +
//               ".jpg"
//             }
//           />
//           <img
//             className={"cropped " + this.state.top}
//             src={
//               this.state.directory + "P1010" + this.state.picNumberTop + ".jpg"
//             }
//           />
//         </div>
//       </React.Fragment>
//     );
//   }
// }

class TestArea extends Component {
  state = {
    checked: false,
    directory: "/img/SynappAvatar/",
    picNumberTop: "060",
    picNumberBottom: "078",
    phase: 0,
    top: "",
    bottom: "",
    avatarArray: [],
    image: null,
  };

  _isMounted = false;

  //======================  MOUNTING AND API LOGIC ===========================
  //==========================================================================
  //==========================================================================

  componentDidMount = async () => {
    this._isMounted = true;
    let avatarArray = [];
    let count = 0;
    //let thisRow = [];

    if (this._isMounted) {
      this.pingImageChange = setInterval(
        () => this.handlePingImageChange(),
        1000
      );

      for (var i = 0; i < 12; i++) {
        let thisRow = [];
        for (var k = 0; k < 12; k++) {
          count = (60 + (i * 12 + k)).toString();
          thisRow.push({
            file:
              count.length === 2
                ? "P10100" + count + ".jpg"
                : "P1010" + count + ".jpg",
          });
        }
        avatarArray.push(thisRow);
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    clearInterval(this.pingImageChange);
  };

  //======================  CHILD COMPONENT LOGIC ============================
  //==========================================================================
  //==========================================================================

  //=========================  COMPONENT LOGIC ===============================
  //==========================================================================
  //==========================================================================

  handlePingImageChange = () => {
    const { picNumberBottom, phase } = this.state;

    if (phase === 0) {
      this.setState({
        phase: 1,
        top: "ibHidden",
        bottom: "ibVisible",
      });
    } else if (phase === 1) {
      this.setState({
        phase: 2,
        picNumberTop: picNumberBottom,
        top: "ibVisible",
        bottom: "ibVisible",
      });
    } else if (phase === 2) {
      this.setState({
        picNumberBottom: this.randomPicNumber(),
        phase: 0,
      });
    }
  };

  //============================ CLICK HANDLES  =============================
  //==========================================================================
  //==========================================================================

  //===============================  RENDER  =================================
  //==========================================================================
  //==========================================================================

  randomPicNumber = () => {
    let number = Math.floor(Math.random() * 151 + 60).toString();
    if (number.length === 2) number = "0" + number;
    return number;
  };

  handleImageUpload = async (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    const formData = new FormData();
    //const fileName = "testAgain.png";

    files.forEach((file, i) => {
      formData.append("File", file);
    });

    this.setState({ formData });

    // const response = await uploadImage("asdasdasd", fileName, formData);

    // if (response && response.success) {
    //   console.log("Image uploaded!");
    //   this.setState({ image: fileName });
    // }
  };

  sendImage = async (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    let fileName = "testFuel.png";
    fileName = document.getElementById("fileName").value;
    formData.append("FileName", fileName);
    formData.append("FolderId", "5ea1f0518b9d0c2e9c9119de");

    const { data: response } = await uploadImage(
      // "5ea1f0518b9d0c2e9c9119de",
      // fileName,
      formData
    );

    if (response && response.success) {
      console.log("Image uploaded!");
      this.setState({ image: response.payload.id + ".ref" });
    }

    //const { data: response } = await uploadImage(this.state.file);
  };

  render() {
    return (
      <React.Fragment>
        {/* <div className="imageBlender">
          <img
            className={"cropped " + this.state.bottom}
            src={
              this.state.directory +
              "P1010" +
              this.state.picNumberBottom +
              ".jpg"
            }
          />
          <img
            className={"cropped " + this.state.top}
            src={
              this.state.directory + "P1010" + this.state.picNumberTop + ".jpg"
            }
          />
        </div> */}
        <p>{this.state.image}</p>
        <div>
          <p>
            <input type="file" id="single" onChange={this.handleImageUpload} />
          </p>
          <p>
            <input type="text" id="fileName" />
          </p>
          <p>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.sendImage}
            >
              Upload
            </button>
          </p>
        </div>
        <p>
          <i className={" fa fa-file-image"}></i>
        </p>
        {this.state.image && (
          <img
            src={"http://jamesdanielsen.com/uploads/" + this.state.image}
            alt="asdasd"
          ></img>
        )}
      </React.Fragment>
    );
  }
}

//============================ UI RENDER LOGIC =============================
//==========================================================================
//==========================================================================

export default TestArea;
