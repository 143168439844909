import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

class NavbarLocal extends Component {
  state = {};

  render() {
    const { user } = this.props;
    const userClaims = user !== undefined ? user.claims : "";

    return (
      // <Navbar bg="dark" expand="lg" variant="dark" className="navBar">
      <Navbar expand="lg" variant="dark" className="navBar">
        <div className="container">
          <img src={Logo} alt="logo" className="mr-3 navBarLogo"></img>
          <Navbar.Brand className="navBarBrand" href="/home">
            James Danielsen
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/Home">Home</Nav.Link>
              <Nav.Link href="/About">About</Nav.Link>

              <NavDropdown title="Projects" id="basic-nav-dropdown">
                {/* <NavDropdown.Divider />  */}
                <NavDropdown.Item href="/Thesis/">Thesis</NavDropdown.Item>
                {/* <NavDropdown.Item href="/Chess/">Chess</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/Voting/">Voting</NavDropdown.Item> */}
                <NavDropdown.Item href="/Papers/">Papers</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            {!this.props.user && (
              <Nav>
                <Nav.Link className="mr-auto" href="/Accounts/Register">
                  Register
                </Nav.Link>
                <Nav.Link className="mr-auto" href="/Accounts/Login">
                  Login
                </Nav.Link>
              </Nav>
            )}
            {this.props.user && (
              <Nav>
                {userClaims.includes("SuperAdmin") && (
                  <NavDropdown title="Admin" id="admin-nav-dropdown">
                    <NavDropdown.Item href="/Accounts/Admin">
                      Dashboard
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/cms/book">
                      CMS: Book
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/cms/document">
                      CMS: Document
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/testarea">
                      TestArea
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
                <Nav.Link className="mr-auto" href="/Accounts/Profile">
                  {this.props.user.username}
                </Nav.Link>
                <Nav.Link className="mr-auto" href="/Accounts/Logout">
                  Logout
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}

export default NavbarLocal;
