import React, { Component } from "react";
// import { toast } from "react-toastify";
import { forgotPassword } from "../../services/userService";
// import { Link } from "react-router-dom";

class ForgotPassword extends Component {
  state = { email: "" };

  handleSubmitForget = async e => {
    e.preventDefault();

    console.log("Email: ", this.state.email);

    const { data: result } = await forgotPassword(this.state.email);

    this.setState({ result: result, success: result.success });
  };

  handleInput = e => {
    let email = { ...this.state.email };
    email = e.target.value;
    this.setState({ email });
  };

  render() {
    return (
      <div>
        <h1>Forgot Password</h1>
        {!this.state.result && (
          <form onSubmit={this.handleSubmitForget}>
            <div className="form-group">
              <label htmlFor="email">Email address: </label>
              <input
                autoFocus
                value={this.state.username}
                onChange={this.handleInput}
                name="email"
                type="text"
                className="form-control"
                id="email"
              />
            </div>
            <button className="btn btn-primary">
              Send password reset token
            </button>
          </form>
        )}
        {this.state.result && this.state.success && (
          <h1>
            A reset password link has been sent to the email address provided.
          </h1>
        )}
        {this.state.result && !this.state.success && (
          <h1>{this.state.result.message}</h1>
        )}
      </div>
    );
  }
}

export default ForgotPassword;
