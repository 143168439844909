import React, { Component } from "react";

class CheckInput extends Component {
  state = {};

  handleCheckInput = (e) => {
    let target = { ...this.props.target };
    // target[e.target.name] = e.target.value;
    target[e.target.name] = e.target.checked;
    let returnObject = {};
    //CHANGED HERE
    target.changed = true;
    returnObject[target.contentType] = target;

    this.props.resetState(returnObject);
  };

  render() {
    const { target, property, label } = this.props;

    return (
      <React.Fragment>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                type="checkbox"
                name={property}
                checked={target[property]}
                onChange={this.handleCheckInput}
                className="mr-2"
                // aria-label="Checkbox for following text input"
              />
              {"  " + label}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckInput;
