import http from "./httpService";
import config from "../config.json";


const apiEndpoint = config.apiUrl + "accounts/";

export function register(newUser) {
    return http.post(apiEndpoint + "register", {
        Username: newUser.username,
        Email: newUser.email,
        Password: newUser.password,
        Code: newUser.registrationCode
    })
}

export function login(username, password) {
    return http.post(apiEndpoint + "signin", {
        Username: username,
        Password: password
    })
}

export async function logout() {
    return await http.get(apiEndpoint + "signout")
}

export async function confirmEmail(token) {
    return await http.get(apiEndpoint + "confirmemail/" + token)
}

export async function forgotPassword(email) {
    return await http.post(apiEndpoint + "forgotpassword", {
        Email: email
    });
}

export async function getResetPassword(token) {
    return await http.get(apiEndpoint + "resetpassword/" + token)
}

export async function postResetPassword(resetForm) {
    return await http.post(apiEndpoint + "resetpassword", {
        ResetToken: resetForm.token,
        Password: resetForm.password,
        PasswordConfirmation: resetForm.passwordConfirm
    });
}