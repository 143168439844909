import http from "./httpService";
import config from "../config.json";


const apiEndpoint = config.apiUrl + "accounts/";

export function checkAccessTokenStillValid() {
  const expiration = localStorage.getItem("expirationTime");
  if (expiration === "null" || expiration === undefined) return false;
  if (Date.now() / 1000 >= expiration) return false;
  else return true;
}

export async function refreshAccessToken() {
  //console.log("In await");
  const refreshToken = localStorage.getItem("refreshToken");
  const { data: result } = await http.post(apiEndpoint + "refreshaccesstoken", {
    RefreshToken: refreshToken
  });
  //console.log("In function log:", result);

  return result;
}
