import React, { Component } from "react";
import { confirmEmail } from "../../services/userService";
import { Container } from "react-bootstrap";

class ConfirmEmail extends Component {
  state = {};

  componentDidMount = async () => {
    const token = this.props.match.params.token;
    console.log("Con token: ", token);
    const { data: result } = await confirmEmail(token);
    //console.log("Confirm result: ", result);

    if (result.success) {
      this.setState({ success: true, result: result });
    } else {
      this.setState({ success: false, result: result });
    }
  };

  render() {
    return (
      <Container>
        <h2>Email Confirmation</h2>
        {this.state.result && this.state.success && (
          <div>
            Your email was successfully confirmed! Please login into continue.
          </div>
        )}
        {this.state.result && !this.state.success && (
          <div>{this.state.result.message}</div>
        )}
      </Container>
    );
  }
}

export default ConfirmEmail;
