import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="footerMargin"></div>
        <div className="navFooter"></div>
      </React.Fragment>
    );
  }
}

export default Footer;
