import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import { deleteContentChunk } from "../../services/cmsService";

class UtilityBar extends Component {
  state = { test: "empty" };

  handleContentInput = (e) => {
    const elementArray = [].concat(this.props.elementArray);
    let thisElement = elementArray.find(
      (x) => x.id === this.props.utilityBarState.activeElementId
    );
    thisElement.content = e.target.value;
    thisElement.changed = true;
    // elementArray.find(
    //   (x) => x.id === this.props.utilityBarState.activeElementId
    // ).content = e.target.value;
    this.props.resetState({ elementArray });
  };

  handleSelectChange = (e) => {
    const elementArray = [].concat(this.props.elementArray);
    let thisElement = elementArray.find(
      (x) => x.id === this.props.utilityBarState.activeElementId
    );
    thisElement.settings[e.target.name] = e.target.value;
    thisElement.changed = true;
    // elementArray.find(
    //   (x) => x.id === this.props.utilityBarState.activeElementId
    // ).settings[e.target.name] = e.target.value;
    this.props.resetState({ elementArray });
  };

  handlePrimarySelectChange = (e) => {
    const elementArray = [].concat(this.props.elementArray);
    let utilityBarState = { ...this.props.utilityBarState };
    const targetType = e.target.value;
    utilityBarState.activeType = targetType;

    let thisElement = elementArray.find(
      (x) => x.id === this.props.utilityBarState.activeElementId
    );
    thisElement.type = targetType;
    thisElement.changed = true;

    // elementArray.find(
    //   (x) => x.id === this.props.utilityBarState.activeElementId
    // ).type = targetType;

    this.props.resetState({ elementArray, utilityBarState });
  };

  setEditState = () => {
    let utilityBarState = { ...this.props.utilityBarState };
    utilityBarState.editActive = !utilityBarState.editActive;
    this.props.resetState({ utilityBarState });
  };

  //Delete wont work with update!
  deleteElement = async () => {
    const elementId = this.props.utilityBarState.activeElementId;
    const { data: response } = await deleteContentChunk(elementId);

    if (response && response.success) {
      let elementArray = cloneDeep(this.props.elementArray);
      let utilityBarState = { ...this.props.utilityBarState };
      const index = elementArray.findIndex(
        (x) => x.id === utilityBarState.activeElementId
      );
      utilityBarState.activeElementId = null;
      utilityBarState.active = false;
      elementArray.splice(index, 1);

      //Update sequence numbers
      for (var i = index; i < elementArray.length; i++) {
        elementArray[i].sequenceNumber--;
        elementArray[i].changed = true;
      }

      this.props.resetState({
        utilityBarState: utilityBarState,
        elementArray: elementArray,
      });
    }
  };

  addElement = () => {
    let elementArray = cloneDeep(this.props.elementArray);
    let utilityBarState = { ...this.props.utilityBarState };
    const index = elementArray.findIndex(
      (x) => x.id === utilityBarState.activeElementId
    );

    let newElement = {
      id: index,
      sequenceNumber: index,
      type: "paragraph",
      settings: { paragraphIndentLevel: 0, headingLevel: 1, imageName: "" },
      content: "No content",
      original: true,
    };
    if (index !== elementArray.length) {
      for (var i = index; i < elementArray.length; i++) {
        elementArray[i].changed = true;
        elementArray[i].sequenceNumber++;
      }
    }
    elementArray.splice(index, 0, newElement);
    this.props.resetState({
      elementArray: elementArray,
    });
  };

  handleImageNameInput = (e) => {
    const elementArray = [].concat(this.props.elementArray);
    let thisElement = elementArray.find(
      (x) => x.id === this.props.utilityBarState.activeElementId
    );
    thisElement.settings[e.target.name] = e.target.value;
    thisElement.changed = true;
    this.props.resetState({ elementArray });
  };

  handleDropImageLink = (ev, selectedElement) => {
    ev.preventDefault();
    //console.log(selectedElement);
    const dragTarget = this.props.dragTarget;
    if (dragTarget && dragTarget.contentType === "imageReference") {
      const elementArray = [].concat(this.props.elementArray);
      let thisElement = elementArray.find(
        (x) => x.id === this.props.utilityBarState.activeElementId
      );
      thisElement.settings.imageName = dragTarget.id + ".ref";
      thisElement.settings.fileName = dragTarget.fileName;
      thisElement.changed = true;
      this.props.resetState({ elementArray });
    }
  };

  handleShiftElement = () => {
    const elementArray = [].concat(this.props.elementArray);
    let index1 = elementArray.findIndex(
      (x) => x.id === this.props.utilityBarState.activeElementId
    );
    const seqNum = elementArray[index1].sequenceNumber;
    console.log("Boom1", seqNum);
    if (seqNum === 0) return false;

    let index2 = elementArray.findIndex((x) => x.sequenceNumber === seqNum - 1);

    //check this
    if (index2 === -1) return false;
    console.log("Boom2", seqNum);
    elementArray[index2].sequenceNumber = seqNum;
    elementArray[index1].sequenceNumber = seqNum - 1;
    elementArray[index1].changed = true;
    elementArray[index2].changed = true;

    elementArray.sort(function (a, b) {
      return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
    });

    this.props.resetState({ elementArray: elementArray });
  };

  // handleDropImageLink = (ev, selectedElement) => {
  //   ev.preventDefault();

  //   // this.setState({ test: this.props.dragTarget.id });
  //   this.setState({});

  // };

  render() {
    const { utilityBarState: state, elementArray } = this.props;
    const selectedElement = elementArray.find(
      (x) => x.id === state.activeElementId
    );
    //TODO: change activeType to currentSelect
    let currentSelect = state.activeType;

    return (
      <Container className="utilityBox">
        {/* <Row>
          <div>{this.state.test}</div>
        </Row> */}
        <Row>
          <Col>
            <label htmlFor="exampleFormControlSelect1">Element type</label>
            <select
              id="exampleFormControlSelect1"
              disabled={!state.active}
              value={currentSelect}
              name="type"
              onChange={this.handlePrimarySelectChange}
            >
              <option>heading</option>
              <option>paragraph</option>
              <option>quote</option>
              <option>quoteblock</option>
              <option>divider</option>
              <option>caption</option>
              <option>image</option>
              <option>table</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col>
            {state.active && (
              <div>
                {currentSelect === "paragraph" && (
                  <div>
                    <label htmlFor="paragraphIndentLevel">
                      Paragraph indentation:
                    </label>
                    <select
                      id="paragraphIndentLevel"
                      value={selectedElement.settings.paragraphIndentLevel}
                      name="paragraphIndentLevel"
                      onChange={this.handleSelectChange}
                    >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </div>
                )}
                {currentSelect === "heading" && (
                  <div>
                    <label htmlFor="headingLevelSelect">Heading size:</label>
                    <select
                      id="headingLevelSelect"
                      value={selectedElement.settings.headingLevel}
                      name="headingLevel"
                      onChange={this.handleSelectChange}
                    >
                      <option>null</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </div>
                )}
                {currentSelect === "image" && (
                  <div className="text-center">
                    {/* <label htmlFor="imageName">Image name:</label>
                    <input
                      id="imageName"
                      value={selectedElement.settings.imageName}
                      name="imageName"
                      onChange={this.handleImageNameInput}
                    ></input> */}
                    {/* {!selectedElement.settings.imageName && (
                      <div
                        className="utilityImageDrop"
                        onDrop={(e) =>
                          this.handleDropImageLink(e, selectedElement)
                        }
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Drop image link here
                      </div>
                    )}
                    {selectedElement.settings.imageName && (
                      <div className="utilityBarText">
                        {selectedElement.settings.fileName}
                      </div>
                    )} */}

                    <div
                      className="utilityImageDrop justify-content-center"
                      onDrop={(e) =>
                        this.handleDropImageLink(e, selectedElement)
                      }
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {!selectedElement.settings.imageName &&
                        "Drop image link here"}
                      {selectedElement.settings.imageName &&
                        selectedElement.settings.fileName}
                    </div>
                  </div>
                )}
                <div>{selectedElement.sequenceNumber}</div>
              </div>
            )}
          </Col>
        </Row>
        {currentSelect !== "image" && (
          <Row className="justify-content-center">
            <button
              className="button btn-primary align-center"
              disabled={!state.active}
              onClick={this.setEditState}
            >
              Edit content
            </button>
            {state.editActive && (
              <Form.Control
                as="textarea"
                id="contentEditor"
                value={selectedElement.content}
                name="contentEditor"
                onChange={this.handleContentInput}
                rows="4"
              />
            )}
          </Row>
        )}
        <Row className="justify-content-around">
          <button
            disabled={!state.active}
            className="button btn-secondary"
            onClick={this.handleShiftElement}
          >
            Shift up
          </button>
          <button
            disabled={!state.active}
            className="button btn-secondary"
            onClick={this.addElement}
          >
            Add blank element
          </button>
          <button
            disabled={!state.active}
            className="button btn-danger"
            onClick={this.deleteElement}
          >
            Delete
          </button>
        </Row>
      </Container>
    );
  }
}

export default UtilityBar;
