import { Component } from "react";
import { logout } from "../../services/userService";
import { toast } from "react-toastify";

class Logout extends Component {
  async componentDidMount() {
    const result = await logout();
    console.log("Logout result:", result);
    if (!result.data.success) {
      toast.error("Failed to logout!");
    }

    if (result.data.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expirationTime");
      window.location = "/";
    }
  }

  render() {
    return null;
  }
}

export default Logout;
