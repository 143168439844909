import React, { Component } from "react";
import axios from "axios";
import http from "../../../services/httpService";
//import { apiUrl } from "../../../config.json";
import  apiUrl from "../../../config.json";

// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import {
  checkAccessTokenStillValid,
  refreshAccessToken
} from "../../../services/tokenService";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

class Test extends Component {
  //FIRST: Unauthorized
  handleTestObjectClick = async () => {
      const { data: response } = await axios.get(apiUrl + "accounts/testobject");
    console.log(response);
    //console.log("Refreshtoken length")
  };

  //SECOND: Test authorized
  handleTestAuthorizedClick = async () => {
    const response = await http.get(apiUrl + "accounts/test");
    console.log(response.headers);

    console.log(response);
  };

  //THIRD: Test claims
  handleTestClaimsBasedClick = async () => {
    const { data: response } = await http.get(apiUrl + "accounts/testclaim");
    console.log(response);
  };

  //FOURTH: Test Storage
  handleCheckLocalStorage = async () => {
    const expiration = localStorage.getItem("expirationTime");
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const claims = localStorage.getItem("claims");
    console.log("Expiration: ", expiration);
    console.log("Access: ", accessToken);
    console.log("Refresh: ", refreshToken);
    console.log("Claims: ", claims);

    try {
      const decodedToken = jwtDecode(accessToken);
      console.log("Decoded token: ", decodedToken);
    } catch (error) {}
  };

  handleCheckRefreshToken = async () => {
    const expiration = localStorage.getItem("expirationTime");
    const time = expiration - Date.now() / 1000;
    console.log(
      checkAccessTokenStillValid(),
      " Time remaining: ",
      time,
      " seconds"
    );
  };

  handlerRenewRefreshToken = async () => {
    //const result = await refreshAccessToken(this.props.history);
    // console.log(
    //   "Current refresh token: ",
    //   localStorage.getItem("refreshToken")
    // );

    const result = await refreshAccessToken();

    if (!result.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expirationTime");
      localStorage.setItem("signout", true);
      //console.log("Token Refresh Result: ", result);
      //toast.error(result.message);
      //this.props.history.push("/accounts/login");
      //history.push("/accounts/login");
      window.location = "/";
      //TODO: check memory leak?
    }
    console.log(result);
    toast("Your access token was refreshed. Mmmm, lamiaceous!");
    localStorage.setItem("accessToken", result.accessToken);
    localStorage.setItem("refreshToken", result.refreshToken);
    localStorage.setItem("expirationTime", result.expirationTime);
  };

  render() {
    return (
      <React.Fragment>
        <h1>Test</h1>
        <p>
          <button
            onClick={this.handleTestObjectClick}
            className="btn btn-primary"
          >
            Get some data from the server (unauthorized)
          </button>
        </p>
        <p>
          <button
            onClick={this.handleTestAuthorizedClick}
            className="btn btn-primary"
          >
            Test your access token is working (authorized)
          </button>
        </p>
        <p>
          <button
            onClick={this.handleTestClaimsBasedClick}
            className="btn btn-primary"
          >
            Test your claim-based access (authorized)
          </button>
        </p>
        <p>
          <button
            onClick={this.handleCheckLocalStorage}
            className="btn btn-primary"
          >
            Check local storage
          </button>
        </p>
        <p>
          <button
            onClick={this.handleCheckRefreshToken}
            className="btn btn-primary"
          >
            How much time left on refreshToken
          </button>
        </p>
        <p>
          <button
            onClick={this.handlerRenewRefreshToken}
            className="btn btn-primary"
          >
            Renew your refreshToken
          </button>
        </p>
      </React.Fragment>
    );
  }
}

export default Test;

// const timestamp = Date.now(); // This would be the timestamp you want to format
//     //console.log(timestamp);
//     console.log(
//       new Intl.DateTimeFormat("en-US", {
//         year: "numeric",
//         month: "2-digit",
//         day: "2-digit",
//         hour: "2-digit",
//         minute: "2-digit",
//         second: "2-digit"
//       }).format(response.testData * 1000)
//     );
//     console.log(
//       new Intl.DateTimeFormat("en-US", {
//         year: "numeric",
//         month: "2-digit",
//         day: "2-digit",
//         hour: "2-digit",
//         minute: "2-digit",
//         second: "2-digit"
//       }).format(timestamp)
//     );
//     //console.log(response, Date.now());
