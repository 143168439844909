import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "../css/app2.scss";
import "../css/document.scss";
import "../css/keyFrames.scss";

import NavbarLocal from "./navigation/navbar";
import UpdaterContainer from "./updaterContainer";
import About from "./about";
import Home from "./home";
import Thesis from "./thesis/thesis";

//import ViewChapter from "./thesis/viewChapter";
import ViewChapter from "./thesis/viewChapter_F";
import Login from "./accounts/login";
import Logout from "./accounts/logout";
import Register from "./accounts/register";
import Test from "./accounts/admin/test";
import Profile from "./accounts/profile";
import Admin from "./accounts/admin/admin";
import TestArea from "./accounts/admin/TestArea";
import ConfirmEmail from "./accounts/confirmEmail";
import ForgotPassword from "./accounts/forgotPassword";
import jwtDecode from "jwt-decode";
import ResetPassword from "./accounts/resetPassword";
import Portfolio from "./portfolio";

import Footer from "./navigation/footer";
import HandleDocument from "./cms/handleDocument";

import Papers from "../components/projects/papers/papers";

import PaperLoader_F from "../components/projects/papers/paperLoader_F";

class App extends Component {
  state = {
    routes: [
      //{ path: "/chess", component: Chess },
      //{ path: "/chess/game/:gameId", component: ChessGame },
    ],
  };

  //Todo: check exact matches on routing

  componentDidMount() {
    const accessToken = localStorage.getItem("accessToken");

    console.log("app load: " + this.props.history)

    try {
      const decodedToken = jwtDecode(accessToken);
      const user = {
        username: decodedToken["use"],
        email: decodedToken["ema"],
        claims: decodedToken["rol"],
        tes: decodedToken["tes"],
        refreshExpirationTime: decodedToken["exp"] * 1000,
      };

      console.log(
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
          //.format(Date.now())
          .format(decodedToken["exp"] * 1000)
      );

      console.log(user);

      this.setState({ user: user });
    } catch (error) {}
  }

  render() {
    const { routes } = this.state;
    return (
      <React.Fragment>
       
            <main>
                
          <NavbarLocal user={this.state.user} />
          <UpdaterContainer user={this.state.user} />
          <ToastContainer />

                <Routes>
          <Route path="/About" element={<About/>} />

         
          {routes.map((route, index) => (
            <Route
              key={"Route" + index}
              exact
              path={route.path}
              element={(props) => (
                <route.component user={this.state.user} {...props} />
              )}
            ></Route>
          ))}
        
          <Route path="/Portfolio" element={<Portfolio/>} />
          <Route path="/Thesis/" exact element={<Thesis />} />
          <Route
            path="/Thesis/ViewChapter/:chapter"
            exact
            // render={(props) => (
            //   <ViewChapter key={props.match.params.chapter} {...props} />
            // )}
            element={
              <ViewChapter/>
            }
          />
          <Route
            path="/Test"
            exact
            render={(props) => <Test appUser={this.state.user} {...props} />}
          />
          <Route
            path="/Accounts/Profile"
            render={(props) => <Profile user={this.state.user} {...props} />}
          />
          <Route
            path="/Accounts/Admin"
            render={(props) => <Admin user={this.state.user} {...props} />}
          />
          
          <Route
            path="/TestArea"
            render={(props) => <TestArea user={this.state.user} {...props} />}
          />
                    <Route path="/Accounts/ForgotPassword" element={<ForgotPassword/>} />
                    <Route path="/Accounts/Logout" element={<Logout />} />
                    <Route path="/Accounts/Login" element={<Login />} />
                    <Route path="/Accounts/Register" element={<Register />} />
          <Route
            path="/Accounts/ConfirmEmail/:token?"
            component={<ConfirmEmail/>}
          />
          <Route
            path="/Accounts/ResetPassword/:token?"
            element={<ResetPassword/>}
          />
        
        
        <Route path="/Home" exact element={<Home />} />
        <Route path="/index.html" exact element={<Home />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/cms/document" exact element={<HandleDocument />} />
        <Route path="/papers" exact element={<Papers />} />
        
        <Route path="/papers/:documentId" exact element={<PaperLoader_F />} />
        
      </Routes>
                    <Footer />

        </main>
      </React.Fragment>
    );
  }
}

export default App;
